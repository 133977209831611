.global-header {
  height: em($global-header-height);

  &__wrapper {
    position: fixed;
    z-index: $zindex__global-header;
    width: 100%;
    padding: 0 em($wrapper-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color__white;
    height: inherit;
    border-bottom: 2px solid $color__gray-aluminium;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $color__white;
    }
  }

  &__block-left {
    flex-grow: 1;
  }

  &__block-right {
    flex-shrink: 0;
  }

  &__home {
    @include transition(width);
    transform: translateZ(0);
    font-size: 1em;
    display: block;
    width: em(245px);
    position: relative;
  }

  &__logo {
    width: 100%;
  }

  &__block-right {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__menu {
    display: none;
    z-index: 2;
  }
  
  @include respond-to('laptop') {
    height: em($global-header-height--tablet);
    

    &--condensed &{ 

      &__wrapper {
        height: em($submenu-height--tablet);
        //-webkit-transition:height 3s ease;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
      }
    }

    .hamburger {
      display: none;
    }



    &__home {
      width: em($logo-width--tablet);
      transition: width .5s ease;
    }

    &__wrapper {
      transition: height .35s linear;
      padding-left: em($wrapper-padding--tablet);
      padding-right: 0;
      border-bottom: 0 none;
      height: em($global-header-height--tablet);
    }


    &__block-right {
      display: inline-flex;
      align-items: center;
      align-self: stretch;
      padding-right: em($wrapper-padding--tablet);
      padding-left: em(15px);
      position: relative;
    }

    &__menu {
      display: block;
    }



    &--workbench-active {
      .global-flyout--is-expanded {
        background-color: $color__black-light;
      }
    }

    &__menu-toggle {
      $font-size: 16px;

      @include font-weight('bold');
      font-size: em($font-size);
      text-transform: uppercase;
      position: relative;

      &+& {
        margin-left: 0.75em;
      }


      &--search {
        @include font-weight('regular');

        // transform: rotateY(180deg);
        vertical-align: bottom;
      }

      &--dots {
        @include font-weight('regular');
        font-size: 1em;

        .icon {
          &:before {
            font-size: em(6px);
          }
        }
      }

      &:hover {
        color: $color__gunmetal;

        &:before {
          width: 100%;
        }
      }

      &--is-expanded,
      &--is-active {
        &:before {
          width: 100%;
        }
      }

      &--is-expanded,
      &--is-active {
        .global-header__menu &.global-header__menu-toggle--blue {
          color: $color__brand-aqua;

          &:before {
            background-color: $color__brand-aqua;
          }
        }
      }

      &--search {
        .global-header__menu & {
          &:before {
            background-color: $color__gray-dim;
          }

          &:hover {
            color: $color__gray-dim;
          }
          .global-header__menu-toggle--is-expanded & {
            color:red;
          }
        }
        
      }

      &--aqua#{&}--is-expanded {
        .icon-dots {
          color: $color__brand-aqua;
        }
      }
    }
  }

  @include respond-to('desktop') {

    &--workbench-active & {

      &__wrapper {
        background-color: transparent;

        &:before {
          background-color: inherit
        }
      }

      &__menu-toggle {
        color: $color__white;
      }
    }

    &__home {
      width: em($logo-width--desktop);
      transition: width .5s ease;
    }

    &__wrapper {
      padding-left: em($wrapper-padding--desktop);
    }

    &__block-right {
      padding-right: em($wrapper-padding--desktop);
    }

    &__menu-toggle {
      $font-size: 18px;
      font-size: em($font-size);

      &+& {
        margin-left: 1em;
      }
      &:before {
        @include transition(width);
  
        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 0%;
        height: rem(4px);
        top: em(-8px, 20px);
        background-color: $color__gunmetal;
      }
    }
  }
  @include respond-to('desktop-large') {
    &__home {
      width: em($logo-width--desktop-large);
    }
    &__menu-toggle {
      $font-size: 20px;
      font-size: em($font-size);

      &+& {
        margin-left: 1.5em;
      }
    }
  }
}