.tabbed-scenarios {
  background-color: $color__gray-soft;
  margin: 0 em($wrapper-padding * -1);

  &__nav {
    display: flex;
    flex-wrap: nowrap;
   
    
    &-option {
      width: 33.3333%;
      flex-grow: 1;

      &--active {
        width: 40%
      }
    }
  }

  &__switch {
    @include font-weight('semibold');

    font-size: px-to-em($font-size__h3);
    width: 100%;
    display: block;
    background-color: $color__gray--f7;
    color: $color__brand-green;
    padding: em($spacing-base/2) 0.25em;

    &--selected {
      background-color: $color__gray-soft;
      color: $color__text;
    }

    &--disabled {
      background-color: $color__gray--fc;
      color: $color__gray-silver;
      cursor: default;
    }
  }

  &__panels {
    padding: em($spacing__module) em($wrapper-padding) ;
  }

  @include respond-to('between') {
    margin: 0;

     &__panels {
      padding: em($spacing__module) ;
    }

    &__nav {
      &-option {
        &--active {
          width: 33.3333%;
        }
      }
    }

    &__switch {
      font-size: px-to-em($font-size__h3--tablet);

      &:before {
        content: "+";
        display: inline-block;
        margin-right: -1em;
        font-size: 1.2em;
        line-height: 1;
        transform: translateX(-1.2em);
        vertical-align: middle;
      }
    }
  }
}