html {
	font-size: 100%;
	box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  font: map-get($font-weights, 'regular') 100% $font-family__sans-serif;
	line-height: $font__line-height;
	color: $color__text;
	overflow-x: hidden;

	&.lock-page-scroll {
		height: 100vh;
		overflow: hidden;
		
		main {
			position: static;
		}
	}
}

main,
#app {
	display: block;
}

a {
	color: inherit;
	text-decoration: none;

	&:focus {
		outline: 1px dotted;
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

li {
	margin-bottom: 0;
}

p,
blockquote, .blockquote {
  margin:0 0 px-to-em($spacing__normalize);

  &:last-child {
  	margin-bottom: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
	line-height: 1.2;
  margin: 0;
	
	@include font-weight('bold')
}

fieldset {
	border: 0 none;
	padding: 0;
	margin: 0;
}

address {
	font-style: normal;
}

strong {
	@include font-weight(bold);
}
