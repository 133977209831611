.search-filters {

  $item-spacing: 0.5em;

  &__list {
    display: flex;
    flex-wrap: wrap;
    
    align-items: stretch;
    margin: 0 $item-spacing * -1;
  }

  &__list-item {
    margin: $item-spacing;
  }
  &__btn {
    text-transform: none;
    border-width: 2px;

    &--is-active {
      color: $color__white;
      border-color: $color__brand-aqua;
      background-color: $color__brand-aqua;

      .theme-purple &{
        background-color: $color__brand-purple;
      }
      .theme-orange &{
        background-color: $color__brand-orange;
      }
    }
  }

  &__label {
    margin-bottom: 0.75em;
  }

  @include respond-to('between') {
    &__btn {
      &:hover {
        color: $color__white;
        border-color: $color__brand-aqua;
        background-color: $color__brand-aqua;
        .theme-purple &{
          background-color: $color__brand-purple;
        }
        .theme-orange &{
          background-color: $color__brand-orange;
        }
      }
    }
  }
}
