//standard
$spacing-base: $font-size-base;

//variation
$spacing__sm: 24px;
$spacing__bt-sm: 40px;
$spacing__md: 50px;
$spacing__lg: 80px;
$spacing__xl: 120px;

$spacing__unit: 10px;

$spacing__gutter: 30px;

$spacing__module: $spacing__md;
$spacing__module--between: $spacing__md;

$spacing__normalize: $spacing-base * $font__line-height;