.social-links {
  $box-size: 30px;

  &__item {
    display: inline;
  }
 
  &__link {
    @include ico-icon();
    display: inline-block;

    &--boxed {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $color__gunmetal;
      width: em($box-size);
      height: em($box-size);
    }
    &--footer {
      font-size: 1.25em;
      margin-right: 1em; 
    }
  }

  .icon {
    vertical-align: middle; 
  }

  @include respond-to('between') {
    $box-size: 50px;

    &__link {
      &--boxed {
        width: em($box-size);
        height: em($box-size);
      }
    }

    &--footer {
      font-size: 1.25em;
    }
  }
}
  