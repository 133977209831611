.holder-figure {
  $ball-size: 10px;

  & + & {
    margin-top: em($spacing__sm);
  }

  &__number {
    position: relative;
    padding-left: 0.75em;

    &:before {
      @include centeralign('vertical');
      content: '';
      left: 0;
      display: inline-block;
      width: em($ball-size, $font-size__display-3);
      height: em($ball-size, $font-size__display-3);
      border-radius: 50%;
      background-color: $color__black;
      vertical-align: middle;
    }

    &--green {
      &:before {
        background-color: $color__brand-green;
      }
    }

    &--purple {
      &:before {
        background-color: $color__brand-purple;
      }
    }

    &--orange {
      &:before {
        background-color: $color__brand-orange;
      }
    }

    &--blue {
      &:before {
        background-color: $color__brand-blue;
      }
    }
  }

  @include respond-to('between') {
    $ball-size: 18px;

    & + & {
      margin-top: em($spacing__gutter);
    }

    &__number {
      padding-left: 1em;
      
      &:before {
        @include centeralign('vertical');
        content: '';
        width: em($ball-size, $font-size__display-3--tablet);
        height: em($ball-size, $font-size__display-3--tablet);
      }
    }
  }
}