.error-modal{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
    display: table;
    transition: all .3s ease;
    
    &__wrapper {
        display: table-cell;
        vertical-align: middle;
      }
      
      &__container {
        width: 85%;
        margin: 0px auto;
        padding: 20px;
        background-color: $color__brand-chile;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
        color: $color__white;
        
        @include respond-to('between') {
            width: 500px;
        }
      }
  
      &__body {
        margin: 20px 0;
      }

      &__close{
          float: right;
          cursor: pointer;
      }
}