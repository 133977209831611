.link {
  @include titles-h4;
  @include font-weight('bold');
  @include transition(color, background-color);

  &--blue {
    color: $color__blue-pelorous;
  }

  @include respond-to('between') {
    &--blue {
      &:hover {
        color: $color__black;
      }
    }
  }
}
