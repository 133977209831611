.valuation {
  &-input {
    &-submit {
      margin: em($spacing__gutter) 0;
      display:inline-flex;
      align-items: center;
      justify-content: center;
    }
    &-clear {
      margin-left: 1.5em;
      color: $color__brand-chile;
      text-decoration: underline $color__brand-chile;
      cursor: pointer;
    }
  }
  &-grid {
    margin-top: em(16px);
    &__item {
      &+& {
        margin-top: em($spacing__gutter);
      }
    }

    &__title {
      margin-bottom: 1em;
      //margin-bottom: em($spacing__gutter, $font-size__h3);
      @include font-weight('semibold');
    }

    @include respond-to('between') {
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;

      &__title {
        width: 100%;
        //margin-bottom: em($spacing__gutter, $font-size__h3--tablet);
      }

      &__item {
        width: 33.3333%;
        flex-grow: 1;
        margin-bottom: em(32px);

        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3) {
          margin-bottom: 0;
        }


        &+& {
          margin-top: 0;
        }
      }
    }
  }
}