
.global-submenu {
  position: fixed;
  width: 100%;
  height: em($submenu-height);
  background-color: $color__gunmetal;
  z-index: $zindex__global-nav;
  

  &__mobile-toggle {
    @include font-weight('semibold');
    width: 100%;
    text-align: left;
    color: $color__white;
    padding: 1em 0;
    text-transform: uppercase;
    padding-right: 1.25em;
    position: relative;
    color: $color__brand-orange;
    background-color: $color__gunmetal;
    position: relative;
    z-index: 5;
    padding-left: em($wrapper-padding);
    padding-right: em($wrapper-padding);

    @include ico-icon-after('chevron-down') {
      position: absolute;
      right: em($wrapper-padding);
    }
  }

  &__container {
    height: em($submenu-height);
    display: none;
  }

  &__list {
    @include transition(transform);
    background-color: $color__gunmetal;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-100%);
  }

  &__list-item {
    & + & {
      padding-top: 1em;
    }

    &:last-child {
      padding-bottom: 1em;
    }
  }

  &__link {
    @include font-weight('semibold');
    @include transition(color, font-size);
    
    color: $color__brand-orange;

    &--is-active {
      color: $color__white;
    }
  }

  &--expanded & {
    &__mobile-toggle {
      &:after {
        transform: rotateX(180deg);
      }
    }
    
    &__list {
      transform: translateY(0%);
    }
  }

  @include respond-to ('between') {
    height: em($submenu-height--tablet);
    transition: transform .35s linear;


    &__container {
      height: em($submenu-height--tablet);
      display: block;

      .global-header--condensed +  &  {
        .global-submenu {
          transform: translateY( ($global-header-height--tablet - $submenu-height--tablet) * -1)
        }
      }
    }

    &__alignment {
      height: 100%;
    }

    &__mobile-toggle {
      display: none;
    }

    &__list {
      @include transition(transform);
      background-color: transparent;
      width: auto;
      position: relative;
      left: auto;
      bottom: auto;
      border-bottom: 0 none;
      transform: none;
      display: flex;
      height: 100%;
      align-items: center;
    }

    &__list-item {
      
      & + & {
        padding-top: 0;
        padding-left: 3vw;
      }

      &:last-child {
        border-bottom: 0 none;
        padding-bottom: 0;
      }
    }

    &__link {
      @include font-weight('bold');

      color: $color__white;
      text-transform: uppercase;
      padding-top: em(8px);
      position: relative;
      display: inline-block;

      &:before {
        @include transition(width);

        content: '';
        display: block;
        position: absolute;
        right: 0;
        width: 0%;
        height: rem(4px);
        top: 0;
        background-color: $color__white;
      }

      &:hover {
        &:before {
          width: 100%;
        }
      }
      
      &--is-active {
        color: $color__brand-orange;

        &:before {
          width: 100%;
          background-color: $color__brand-orange;
        }

        .theme--orange & {
          color: $color__brand-orange;
          &:before {
            background-color: $color__brand-orange;
          }
        }

        .theme--aqua &{
          color: $color__blue-pelorous;
          &:before {
            background-color: $color__blue-pelorous;
          }
        }
      }
    }
  }
}
