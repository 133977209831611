.sidenav {
  width: 100%;  
  position: relative;
  z-index: $zindex__pageheading - 5;
  margin-bottom: em($spacing__gutter);

  &__mobile-toggle {
    @include font-weight('semibold');
    text-transform: uppercase;
    padding: 1em;
    padding-right: 1.25em;
    position: relative;
    color: $color__gunmetal;
    background-color: $color__gray-light;
    position: relative;
    z-index: 5;
    width: 100%;
    text-align: left;
    border: 0 none;

    @include ico-icon-after('chevron-down') {
      position: absolute;
      right: 1em;
      color: $color__brand-orange;
    }

    .section--padding &{
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        background-color: $color__white;
        height: em($spacing__module);
      }
    }
  }

  &__list {
    @include transition(transform);
    background-color: $color__gunmetal;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    padding-left: em($wrapper-padding);
    padding-right: em($wrapper-padding);
  }

  &__list-item {
    padding-top: 1em;
    
    &:last-child {
      padding-bottom: 1em;
    }
  }

  &__link {
    @include font-weight('semibold');
    @include transition(color, font-size);
    
    color: $color__brand-orange;

    &--is-active {
      color: $color__white;
    }
  }

  &--is-expanded & {
    &__mobile-toggle {
      &:after {
        transform: rotateX(180deg);
      }
    }
    
    &__list {
      transform: translateY(0%);
    }
  }

  &__heading  {
    @include titles-h3;
    @include font-weight('bold');
    margin-bottom: 1em;
    margin-top: 0.1em;
    display: none;
  }

  @include respond-to('between') {
    &__mobile-toggle {

      .section--padding &{
        &:before {
          height: em($spacing__module--between);
          pointer-events: none;
        }
      }
    }
  }

  @include respond-to ('desktop') {
    width: auto;
    position: static;
    background-color: transparent;
    padding-bottom: em($spacing__module--between);
    z-index: $zindex__sticky-content;

    &-nopad {
      padding-bottom: 0;
    }

    &__heading  {
      margin-bottom: 1em;
      margin-top: 0.1em;
      display: block;
    }

    &__mobile-toggle {
      display: none;
    }

    &__list {
      transition: none;
      background-color: transparent;
      width: auto;
      position: relative;
      left: auto;
      bottom: auto;
      padding: 0;
      border-bottom: 0 none;
      transform: none;
    }

    &__list-item {
      padding-top: 0;

      & + & {
        padding-top: 1.25em;
      }

      &:last-child {
        border-bottom: 0 none;
      }
    }

    &__link {
      $font-size: $font-size__h4--tablet;
      $dot-size: 7px;

      @include font-weight('regular');

      color: $color__text;
      font-size: em($font-size);
      line-height: 1.2;
      position:relative;
      &:after {
        @include transition(width);

        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom:0;
        width: 0%;
        height: rem(1px);
        background-color: $color__text;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
      &--active {
        color: $color__brand-orange;
        &:after {
          width:100%;
        }
        .theme--orange & {
          color: $color__brand-orange;
          &:after {
            background-color: $color__brand-orange;
          }
        }

        .theme--aqua & {
          color: $color__brand-aqua;
          &:after {
            background-color: $color__brand-aqua;
          }
        }
      }
    }
  }
}
