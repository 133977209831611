.tool-input {
  margin: em(10px) 0px;
  &__container {
    margin-top: em($spacing__gutter);
  }

  &__input {
    display: inline-block;
  }

  &__label {
    display: block;
    margin-bottom: rem(15px);
    margin-right: rem(5px);
    &--small-grey {
      display: inline-block;
    }
  }

  &__error {
    color: $color__brand-chile;
    font-size: em(12);
    
  }

  &--plain {
    input {
      width: 100%;
      background-color: $color__gray-soft;
      border: 1px solid transparent;
      padding: em(5px) em(10px);

      &:focus {
        background-color: $color__white;
        border: 1px solid $color__tool-green-light;

      }
    }

  }

  &--grey {
    input {
      width: 100%;
      background-color: $color__gray-soft;
      border: 1px solid transparent;
      padding: em(5px) em(10px);

      &:focus {
        background-color: $color__white;
        border: 1px solid $color__tool-green-light;

      }
    }
  }

  &__divider{
    padding-top: em($spacing__gutter);
    border-bottom: 1px solid $color__border--darker;
  }

  &--small-grey {
    display:inline-block;
    input {
      width: em(100px);
      background-color: $color__gray-soft;
      border: 1px solid transparent;
      padding: em(5px) em(10px);

      &:focus {
        background-color: $color__white;
        border: 1px solid $color__tool-green-light;

      }
    }
  }

  &--ornament {
    position: relative;
    display: flex;
    align-items: stretch;

    &:before {
      color: $color__white;
      font-size: em(14px);
      padding: 5px 8px;
      content: "";
      background-color: $color__brand-green;
      display: inline-flex;
      align-items: center;
    }

    input,
    &__input {
      padding: em(5px) em(10px);
      border: 1px solid transparent;

      &:focus {
        border: 1px solid $color__brand-green;
      }
    }

    &--dollar {
      input {
        width: 9em;
      }

      &:before {
        content: "$";
      }
    }

    &--decimal {
      input {
        width: 9em;
      }

      &:before {
        content: "%";
      }
    }

  }

  @include respond-to('between') {
    &--plain {
      input {
        width: em(326px);
      }
    }
  }
  &--small-grey {
    input {
      width: em(100px);
    }
  }

}