@mixin font-face-family($font-family, $file-path, $font-weight, $font-style) {
    @font-face {
        font-family: $font-family;
        src: url('#{$file-path}.eot');
        src: url('#{$file-path}.eot?v=1.0.0') format('embedded-opentype'),
            url('#{$file-path}.woff?v=1.0.0') format('woff'),
            url('#{$file-path}.woff2?v=1.0.0') format('woff2'),
            url('#{$file-path}.ttf?v=1.0.0') format('truetype');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
	@font-face {
		font-family: $font-family;
		src: url('#{$file-path-fonts}#{$file-path}.eot?v=1.0.0');
		src: url('#{$file-path-fonts}#{$file-path}.eot?v=1.0.0') format('embedded-opentype'),
			 url('#{$file-path-fonts}#{$file-path}.woff?v=1.0.0') format('woff'),
			 url('#{$file-path-fonts}#{$file-path}.ttf?v=1.0.0') format('truetype'),
			 url('#{$file-path-fonts}#{$file-path}.svg##{$font-family}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}