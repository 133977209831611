$button__font-size: 18px;
$button__font-size--between: 18px;

$button__padding: 20px;

button {
  @include transition(color, background-color, border-color);
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  border-radius: 0;
  padding: 0;
  border-width: 1px;
  font-family: $font-family__sans-serif;
  overflow: visible;
}

//default styles
.btn {
  @include transition(color, background-color);

  position: relative;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  border: 0 none;
  text-align: center;


  &:focus {
    outline: 1px dotted;
  }
  
  &--disabled{
    color: $color__gray-silver !important;
    border-color: $color__gray-silver !important;
    cursor: inherit !important;      
    &:hover {
      background-color: inherit !important;
    }
  }
}

.btn-ghost {
  color: inherit;
  padding: px-to-em($button__padding/1.5) px-to-em($button__padding);
  line-height: 1.4;
  min-width: px-to-em(225px);
  border: 1px solid $color__text $color__border;
  //text-transform: uppercase;
  font-size: em($button__font-size);
  @include font-weight('semibold');

  &--thick {
    border-width: 2px;
  }

  &--full {
    width: 100%;
  }
  
  &--center {
    text-align: center;
  }


  &--orange {
    color: $color__brand-orange;
    border-color: rgba($color__brand-orange, 0.75);

    &:hover {
      border-color: $color__brand-chile;
    }
  }

  &--purple {
    color: $color__brand-purple;
    border-color: rgba($color__brand-purple, 0.75);

    &:hover {
      border-color: $color__brand-purple;
    }
  }

  &--with-chevron {
    text-align: left;
    padding-left: em(20px);
    padding-right: em(50px);
    position: relative;

    @include ico-icon-after('chevron-right') {
      @include centeralign(vertical);

      right: em(20px);
    }
  }

  &--clearall {
    font-size: em($font-size__small);
    padding: em($button__padding/3, $font-size__small) em($button__padding/2, $font-size__small);
    min-width: inherit;
  }

  &--small {
    font-size: em($font-size__small);
    padding: em($button__padding/3, $font-size__small) em($button__padding/2, $font-size__small);
    min-width: inherit;
  }
}

.btn-cta {
  //@include titles-h2;
  @include font-weight('semibold');
  @include transition(color, background-color);
  color: $color__brand-orange;
  display: inline-block;
  font-size: em($button__font-size);
  &--small {
    
    width: 100%;
  }

  &--uc {
    //text-transform: uppercase;
  }

  &--full {
    display: block;
  }


  &--bg {
    position: relative;
    padding: 0.75em 2em 0.75em 1em;
    background-color: $color__brand-orange;
    color: $color__white;

    &:after {
      @include centeralign('vertical');

      right: 1.25em;
    }

  }

  &--purple-bg {
    background-color: $color__brand-purple;
  }

  &--can-submit {
    color: $color__white;
  }

  @include ico-icon-after('chevron-right') {
    font-size: 0.8em;
    vertical-align: middle;
    margin-left: 0.5em;
  }

  &--underline {
    &:after {
      display: inline;
    }
    &-no-arrow{
      &:after{
        content: "";
      }
    }

  }

}
.btn-form {
  //@include titles-h2;
  @include font-weight('semibold');
  border: 1px solid $color__brand-orange;
  //padding: px-to-em($button__padding/3) px-to-em($button__padding/2);
  padding: 0.75em em(50px) 0.75em em(20px);
  color: $color__brand-orange;
  @include ico-icon-after('chevron-right') {
    @include centeralign(vertical);

    right: em(20px);
  }
  &--valid {
    background-color: $color__brand-orange;
    color: $color__white;
  }
}

@include respond-to('between') {

  .btn-ghost {
    font-size: $button__font-size--between;
    padding: px-to-em($button__padding/1.5, $button__font-size--between) px-to-em($button__padding, $button__font-size--between);
    &--with-chevron {
      padding-left: em(20px);
      padding-right: em(50px);
    }
    &--clearall {
      font-size: em($font-size__small);
    }

    &--small {
      padding: em($button__padding/2.5) em($button__padding/1.5);
      min-width: inherit;
    }
  }

  .btn-cta {
    &:hover:not(.btn-cta--underline) {
      color: $color__black;
    }

    &--bg {
      &:hover {
        background-color: $color__gunmetal;
        color: $color__white;
      }
    }

    &--small {
      font-size: em($button__font-size--between);
      min-width: em(320px, $button__font-size--between);
      width: auto;
    }

    &--valuation {
      font-size: em($button__font-size--between);
      min-width: em(120px, $button__font-size--between);
      width: auto;
      padding-top: .5em;
      padding-bottom: .5em;
    }

    &--underline {
      position: relative;

      &:after {
        content: "";
        display: inline;
      }
    }

    &__link {
      &--underline {
        position: relative;

        &:after {
          @include transition(width);

          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 0%;
          height: rem(1px);
          background-color: $color__text;
          margin-left: 0px;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }

    &__arrow {
      @include ico-icon-after('chevron-right') {
        font-size: 0.8em;
        vertical-align: middle;
        margin-left: 0.5em;
      }

      &--external {

        @include ico-icon-after('arrow-right--bold') {
          font-size: 0.8em;
          vertical-align: middle;
          margin-left: 0.5em;
          transform: rotate(-45deg);
        }
      }
    }
  }
}