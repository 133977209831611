.topic-radio-filters {
  @include transition(background-color);

  margin-bottom: $spacing__module;
  margin-left: em($wrapper-padding * -1);
  margin-right: em($wrapper-padding * -1);
  padding: 0 em($wrapper-padding) em($spacing__unit * 2);

  &--show-filter-groups  {
    background-color: $color__gray--f8;
  }

  &--show-filter-groups  & {
    &__dropdown-heading {
      border-color: transparent transparent $color__border;
      color: $color__text;
      border-width: 1px;

      &:after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }

    &__groups {
      visibility: visible;
      transition: height $transition-duration $transition-easing;
    }
  }

  &__groups {
    overflow: hidden;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    transition: height $transition-duration $transition-easing,
      visibility 100ms linear $transition-duration;
  }

  &__heading {
    padding-bottom: $spacing__unit;
    border-bottom: 1px solid $color__border;
    display: none;
  }

  &__dropdown-heading {
    @include transition(background-color, border-color, color);
    @include font-weight('semibold');
    line-height: 1.4;
    padding: px-to-em($button__padding/1.5) px-to-em($button__padding);
    border: 2px solid $color__text $color__brand-aqua;
    width: 100%;
    font-size: em($button__font-size);
    line-height: 1.1;
    text-align: left;
    padding-right: 2.5em;
    color: $color__brand-aqua;

    @include ico-icon-after('chevron-down') {
      @include centeralign('vertical');
      right: px-to-em($button__padding, $button__font-size);      
    }    
  }

  &__dropdown-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }

  @include respond-to('between') {

  }

  @include respond-to('desktop') {
    padding: em($spacing__unit * 2);
    margin-left: em($spacing__unit * -2);
    margin-right: 0;
    background-color: $color__gray--f8;

    &__heading {
      display: block;
    }

    &__dropdown-heading {
      display: none;
    }

    &__groups {
      height: auto;
      visibility: visible;
      position: relative;
      overflow: visible;
    }    
  }
}
