$wrapper-padding: 22px;
$wrapper-padding--tablet: 30px;
$wrapper-padding--desktop: 50px;

$wrapper-max-width: 1400px;
$wrapper-max-width--narrow: 800px;
$wrapper-max-width--very-narrow: 600px;

.wrapper {
  max-width: px-to-em($wrapper-max-width) +  px-to-em($wrapper-padding--tablet * 2);
  padding-left: px-to-em($wrapper-padding);
  padding-right: px-to-em($wrapper-padding);
  margin-left: auto;
	margin-right: auto;

	&--narrow {
		max-width: px-to-em($wrapper-max-width--narrow) +  px-to-em($wrapper-padding--desktop * 2);
	}

	&--very-narrow {
		max-width: px-to-em($wrapper-max-width--very-narrow) +  px-to-em($wrapper-padding--desktop * 2);
	}


	@include respond-to('between') {
		padding-left: px-to-em($wrapper-padding--tablet);
  	padding-right: px-to-em($wrapper-padding--tablet);
	}

	@include respond-to('desktop') {
		padding-left: px-to-em($wrapper-padding--desktop);
  	padding-right: px-to-em($wrapper-padding--desktop);
	}
}
