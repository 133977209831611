.search-landing {
  &__control,
  &__criteria {
    margin-bottom: em($spacing__sm)
  }

  &__radio {
    line-height: 1.05;
    text-transform: uppercase;
    color: $color__text;
    line-height: 1.2;
    font-size: $font-size__display-4;
    position:relative;
    cursor: pointer;
    &:after {
      @include transition(width);

      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom:0;
      width: 0%;
      height: rem(1px);
      background-color: $color__text;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
    color: inherit;

    input {
      margin-right: 0.5em;
    }
  }

  
  input[type="radio"]{
    display: none;
    &:checked{
      +.search-landing__radio{
        color: $color__brand-aqua;
        &:after {
          width: 100%;
          background-color: $color__brand-aqua;
        }
      }
    }
  }

  &__criteria {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__criteria-all {
    flex-shrink: 0;
    margin-left: 0.5em;
  }

  &__listing-item {
    border-bottom: thin solid $color__border--darker;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: em($spacing__unit * 2) 0;
    padding-right: 1em;

    &:first-child {
      border-top: thin solid $color__border--darker;
    }


    &--no-chevron {
      &:after {
        content: none;
      }
    }
  }

  &__results-message {
    border: 1px solid  $color__border--darker;

    border-width: thin 0;
    padding: em($spacing__gutter)  0;
  }

  &__view-more {
    margin-top: em($spacing__sm)
  }

  &__listing-heading {
    padding-bottom: em($spacing__unit * 2);
  }

  @include respond-to('between') {
    &__view-more {
      &--centered {
        max-width: em(225px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__listing-item {
      padding-right: 2em;

      &:after {
        font-size: em(26px);
      }
    }
    
    &__control,
    &__criteria {
      margin-bottom: em($spacing__gutter)
    }
  }

  &__view-more {
    margin-top: em($spacing__gutter)
  }

  &__divider{
    display: inline-block;
    vertical-align: middle;
    &:after{
      content: '|';
      font-size: em($font-size-base * 1.5);
      margin: 0 0.25em;
    }
  }
}
