.tooltip {
  position: absolute;
  width: em(290px);
  z-index: $zindex__pageheading - 5;
  box-shadow: 0px 0px 5px 0 $color__dropshadow; 
  visibility: hidden;
  opacity: 0;
  transition: opacity $transition-duration $transition-easing,
    visibility 100ms $transition-duration;

  &--show {
    @include transition(opacity);
    visibility: visible;
    opacity: 1;
  }
  
  &__wrapper {
    background-color: $color__white;
    padding: em($spacing__gutter);
    position: relative;
    z-index: 5;
  }


  &:before {
    $size: em(20px);
    content: '';
    display: block;
    width: $size;
    height: $size;
    position: absolute;
    right:1.5em;
    top: 0.1em;
    transform: rotate(45deg) translateY(-1em);
    box-shadow: 0px 0px 6px 0 $color__dropshadow;
    background-color: $color__white;
  }

  &--right-chevron {
    &:before {
      right: auto;
      left: 1.5em;
      // transform: rotate(45deg) translateX(-1em);
    }
  }

  &--center-chevron {
    &:before {
      right: 50%;
    }
  }

  &__title {
    @include font-weight('semibold');

    font-size: px-to-em($font-size__h6);
    line-height: 1.1;
    margin-bottom: 0.5em;
  }

  &__container {
    position: relative;
  }

  &__subtip{
    z-index: $zindex__pageheading - 7;
  }

  &__link {
    @include font-weight('semibold');
    text-decoration:underline;
    display: inline-block;

    &:after {
      $font-size: 11px;
      content: 'i';
      width: em(12px, $font-size);
      height: em(12px, $font-size);
      display: inline-block;
      font-size: em($font-size);
      line-height: 1.3;
      background-color: $color__brand-green;
      border-radius: 50%;
      color: $color__white;
      text-align: center;
      vertical-align: super;
      position: absolute;
    }
  }

  &__button {
    position: absolute;
    top: em($spacing__gutter/2);
    right: em($spacing__gutter/2);
  }

  @include respond-to('landscape') {
    &__link {
      display: inline;
      margin-right: em(16px);
    }

    &:before {
      right: 100%;
      top: 3em;
      transform: rotate(45deg) translateY(-1em);
    }

    &--right-chevron {
      &:before {
        right: auto;
        left: 100%;
        transform: rotate(45deg) translateX(-1em);
      }
    }
  }
}