.scrollspy-nav {
  padding-left: em($wrapper-padding);
  padding-right: em($wrapper-padding);
  width: 100%;
  z-index: $zindex__sticky-content;


  &__list-item {
    & + & {
      padding-top: 1em;
    }
  }

  &__link {
    @include font-weight('bold');
    @include transition(transform);

    font-size: em($font-size__h3);
    color: $color__text;
    backface-visibility: hidden;
    will-change: transform;
    transform-origin: left;

    &--is-active {
      color: $color__brand-orange;
      transform: scale(1.3);
    }
  }

  @include respond-to ('desktop') {
    width: auto;
    background-color: transparent;

    &__list-item {
      & + & {
        padding-top: 1em;
      }

      &:last-child {
        border-bottom: 0 none;
      }
    }

    &__link { 
      font-size: em($font-size__h3--tablet);

      &:hover {
        transform: scale(1.3)
      }
      
      &--is-active {
        color: $color__brand-orange;
        transform: scale(1.3);
      }
    }
  }
}
