.video-launch  {
  position: relative;
  border: 1px solid $color__border--darker;
  border-width: 1px 0;
  padding: 1em 0;

  &--basic-view {
    padding: 0;
    border: 0 none;
  }
  
  &__toggle {
    width: 100%;
  }

  &__tag {
    @include font-weight('bold');

    position: absolute;
    top: 0;
    left: 0;
    background-color: $color__gunmetal;
    color: $color__white;
    text-transform: uppercase;
    font-size: em($font-size__small);
    padding:0.5em 1em;
  }

  &__image {
    max-width: 100%;
    width: 100%;
  }

  &__play-icon {
    $size: 50px;
    @include centeralign('both');

    background-color: $color__red--opaque;
    height: em($size);
    width: em($size);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:after {
      
      content: '';
      display: block;
      width: 0;
	    height: 0;
	    border-top: em(8px) solid transparent;
	    border-left: em(18px) solid $color__white;
	    border-bottom: em(8px) solid transparent;
    }
  }

  &__figure {
    position: relative;
    display: block;
  }

  &__title {
    margin-top: 1em;
    display: block;
  }

  &__iframe{
    max-width: 100%;
  }

  @include respond-to('between') {

    &--basic-view &{
      &__toggle {
        display: block;
      }

      &__figure {
        width: 100%;
        margin-right: 0;
        max-width: none;
      }
    }

    &__toggle {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;
    }

    &__figure {
      flex-shrink: 0;
      width: 40%;
      max-width: em(390px);
      margin-right: 1em;
    }

    &__title {
      flex-grow: 1;
      margin-top: 0;
    }

    &__play-icon {
      $size: 60px;
      height: em($size);
      width: em($size);
    }
  }
}
