.related-tags {
  
  &__list-item {
    display: inline;
    margin-right: 0.5em;

    &:not(:last-child):after {
      content: ', '
    }
  }

  &__tag {
    color: $color__brand-aqua;
    @include font-weight('regular'); 
  }

  @include respond-to('between') {
    &__tag {
      @include transition(color);

      color: $color__brand-aqua;

      &:hover {
        color: $color__gray;
      }
    }
  }
}
