.stickynav {
  width: inherit;
  margin-left: em($wrapper-padding) * -1;
  margin-right: em($wrapper-padding) * -1;

  &__container {
    position: relative;
    padding-top: 0;
  }

  @include respond-to('between') {
    margin-left: em($wrapper-padding--tablet) * -1;
    margin-right: em($wrapper-padding--tablet) * -1;
  }

  @include respond-to('desktop') {
    margin-left: 0;
    margin-right: 0;

    &__container {
      padding-top: em($spacing__module--between);
    }

    &__trigger {
      width: inherit;
  
      &--is-sticky {
        position: fixed;
        top: em($global-header-height--tablet + $submenu-height--tablet + $spacing__module--between);
      }
  
      &--is-bottom-stuck {
        position: absolute;
        top: auto;
        bottom: 0;
      }
    }
  }
}
