$toolbox-menu-height: 60px;
$toolbox-menu-height--between: 70px;

.toolbox-menu {
  position: fixed;
  width: 100%;
  background-color: $color__gunmetal;
  z-index: $zindex__sticky-content;
  bottom: 0;
  left: 0;
  z-index: $zindex__global-nav;
  border-bottom: thin solid $color__white;
  
  &__mobile-toggle {
    @include font-weight('bold');
    
    padding: 1em 0;
    text-transform: uppercase;
    color: $color__white;
    background-color: $color__brand-green;
    position: relative;
    z-index: 5;
    width: 100%;
    height: em($toolbox-menu-height);

    
    
    .icon {
      padding-left: 0.5em;

      &:before {
        @include transition(transform);

        position: relative;
        font-size: em(12px);
        display: inline-block;
      }
    }
  }

  &__list {
    @include transition(transform);
    background-color: $color__gray-soft;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__list-item {
    padding: 1em em($wrapper-padding);

    & + & {
      border-top: 1px solid $color__white;
    }

    &:last-child {
      padding-bottom: 1em;
      border-bottom: 2px solid $color__white;
    }
  }

  &__link {
    @include font-weight('semibold');
    @include transition(color, font-size);
    color: $color__brand-green;

    &--is-active {
      color: $color__white;
    }
  }

  &--is-expanded & {
    &__mobile-toggle {
      .icon {
        &:before {
          transform: rotate(45deg);
        }
      }
    }
    &__list {
      transform: translateY(-100%);
    }
  }

  @include respond-to ('between') {
    position: absolute;

    &__mobile-toggle {
      display: none;
    }

    &__list {
      @include transition(transform);
      // background-color: transparent;
      width: auto;
      position: relative;
      left: auto;
      bottom: auto;
      padding: 0;
      border-bottom: 0 none;
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
    }

    &__list-item {
      padding: 0;
      display: inline-flex;
      flex-direction: column;
      flex-grow: 1;

      & + & {
        padding: 0;
        margin: 0;
        border-top: 0;
        border-left: 1px solid $color__white;
      }

      &:last-child {
        border-bottom: 0 none;
        padding-bottom: 0;
      }

      &:first-child {
        .toolbox-menu__link {
          background-color: $color__brand-green;
          color: $color__gray-soft;
        }
      }
    }

    &__link {
      $font-size: 18px;
      
      @include font-weight('light');
      @include transition(color, background-color);

      font-size: em(18px);
      display: inline-flex;
      min-height: em($toolbox-menu-height--between, $font-size);
      align-items: center;
      text-align: center;
      justify-content: center;
      background-color:$color__gunmetal;
      color: $color__white;

      &:hover {
        background-color: $color__brand-green;
        color: $color__gray-soft;
      }
    }
  }

  @include respond-to-height('laptop', $desktop: true) {
    top: 100%;
    bottom: auto;
  }
}
