$section__sidebar-width: 240px;
$section__sidebar-margin: $spacing__gutter;
$section__column-main-width: 850px;



.section {
  &--main {
    overflow-x: hidden;
  }

  &--dark-gray {
    background-color: $color__gunmetal;
  }

  &--gray-light {
    background-color: $color__gray-soft
  }

  &--roomy {
    &+& {
      margin-top: em($spacing__module);
    }
  }

  &--module {
    &+& {
      margin-top: em($spacing__module);
    }
  }

  &--module-with-border {
    &+& {
      margin-top: em($spacing__bt-sm);
      padding-top: em($spacing__bt-sm);
      border-top: 1px solid $color__border--darker;
    }
  }

  &--bottom-border {
    margin-bottom: em($spacing__module);
    padding-bottom: em($spacing__module);
    border-bottom: 1px solid $color__border--darker;
  }

  &--padding {
    padding-top: em($spacing__module);
    padding-bottom: em($spacing__module);
  }

  .section__title {
    margin-bottom: px-to-em($spacing__unit, $font-size__h2);
  }

  .section__description {
    margin-bottom: px-to-em($spacing__gutter);
  }

  @include respond-to('between') {
    &--roomy {
      &+& {
        margin-top: em($spacing__xl);
      }
    }

    &--module {
      &+& {
        margin-top: em($spacing__module--between);
      }
    }

    &--module-with-border {
      &+& {
        margin-top: em($spacing__module - $spacing__gutter);
        padding-top: em($spacing__module);
        border-top: 1px solid $color__border--darker;
      }
    }

    &--bottom-border {
      padding-bottom: em($spacing__module--between);
    }

    &--padding {
      padding-top: em($spacing__module--between);
      padding-bottom: em($spacing__module--between);
    }

    .section__title {
      margin-bottom: px-to-em($spacing__unit, $font-size__h2--tablet);
    }

    .section__description {
      margin-bottom: px-to-em($spacing__md);
    }
  }

  @include respond-to('desktop') {

    &__columns {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;

      &--empty-sidebar {

        &:before {
          flex-shrink: 0;
          content: '';
          display: block;
          width: em($section__sidebar-width);
          height: 2em;
          flex-shrink: 0;
          margin-right: em($section__sidebar-margin);
        }
      }

      &-sidebar {
        flex-shrink: 0;
        width: em($section__sidebar-width);
        margin-right: em($section__sidebar-margin);

        &--wide {
          width: em(300px);
          margin-right: em($spacing__md);
        }
      }

      &-main {
        flex-grow: 1;
        max-width: em($section__column-main-width);
        flex: 1;
      }
    }
  }
}