.rte {
  line-height: 1.5;
  
  &--light {
    @include font-weight('light');
  }
  

  &--small {
    font-size: px-to-em($font-size__small);
    line-height: 1.4;
  }

  &--lead {
    font-size: em($font-size__copy-lead);
    line-height: 1.25;
  }

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: $color__blue-pelorous;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.1em;
    margin-bottom: px-to-em($spacing__normalize);

    ul {
      list-style-type: circle;
      margin-top: px-to-em($spacing__normalize/2);
    }

  }

  li {
    margin-bottom: px-to-em($spacing__unit/2);
  }

  ol {
    padding-left: 1.1em;
    list-style-type: decimal;
    margin-top: px-to-em($spacing__unit/2);
    margin-bottom: px-to-em($spacing__normalize);

    ol {
      margin-top: px-to-em($spacing__normalize/2);
    }
  }

  blockquote,
  .blockquote {
    @include font-weight('light');
    font-size: em($font-size__blockquote);
    line-height: 1.2;
    margin: px-to-em($spacing__normalize) 0;
    padding-top: em($spacing-base, $font-size__blockquote);
    border-top: 3px solid $color__brand-aqua;

    p {
      margin-bottom: 0.5em;
    }

    cite,
    .cite {
      font-size: em($font-size__small, $font-size__blockquote);
      text-transform: uppercase;
    }
  }

  cite,
  .cite {
    @include font-weight('bold');

    font-style: normal;
    font-size: em($font-size__small);
    color: $color__text-gray;
  }

  //headings
  h2,
  .h2-size {
    font-size: px-to-em($font-size__h2);
    margin-bottom: px-to-em($spacing__unit, $font-size__h2);
  }

  h3,
  .h3-size {
    font-size: px-to-em($font-size__h3);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h3);
  }

  h4,
  .h4-size {
    font-size: px-to-em($font-size__h4);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h4);
  }

  h5,
  .h5-size {
    font-size: px-to-em($font-size__h5);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h5);
  }
  
  h6,
  .h6-size {
    font-size: px-to-em($font-size__h6);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h6);
  }  

  img,
  iframe {
    max-width: 100%;
  }
}