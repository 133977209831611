.video-player  {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex__modal;
  background-color: $color__black--opaque;
  transition: opacity $transition-duration $transition-easing,
    visibility 100ms linear $transition-duration;

  &--active {
    @include transition(opacity 1s);

    opacity: 1;
    visibility: visible;
  }

  &__container {
    @include centeralign('both');
    max-width: em(640px);
    max-height: em(390px);
    width: 100%;


    iframe, object,video {
      max-width: 100%;
    }
  }

  &__close {
    position: absolute;
    bottom: 100%;
    right: 0;
    color: $color__gray-soft;
    margin-bottom: 1em;

    &:before {
      font-size: em(30px);
    }

    &:hover {
      color: $color__white;
    }
  }

  @include respond-to('between') {
    &__close {
      &:before {
        font-size: em(50px);
      }
    }
  }
}

