.figure-highlights  {
  position: relative;
  border: 1px solid $color__border--darker;
  border-width: 1px 0;
  padding: 1em 0;


  &__caption {
    @include font-weight('light');
    
    margin-top: 1em;
    display: block;
  }

  &__img {
    max-width: 100%;
    display:block;

    & + & {
      margin-top: em($spacing__unit);
    }
  }

  @include respond-to('landscape') {
    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &__img {
      width: calc(50% - #{em($spacing__unit/2)});
      margin-bottom: #{$spacing__unit};

      &:first-child:last-child {
        width: auto;
      }

      & + & {
        margin-top: 0;
      }
    }

    &__caption {
      margin-top: 0;
    }
  }
}