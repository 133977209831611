.liquidation-calculator {
  &__radio-input {
    input[type="radio"] {
      margin-right: em(7px);
    }
  }
  &__start-fresh {
    margin-bottom:em($spacing__gutter);
    display:flex;
    justify-content: flex-start;
    &__text{
      display:inline-block;
    }
    &__clear {
      display:inline-block;
      color: $color__brand-chile;
      cursor: pointer;
      margin-left: em(10px);
    }
    &--bold {
      @include font-weight('bold');
    }
  }
  &__section-one {
    margin-bottom:em(56px);
  }
  &__multiple {
    &-container {
      &__input-container {
        display: flex;
      }
      .tool-input__input {
        display: inline;
        
      }
      .tool-input__label {
        margin-bottom: 0px;
      }
      &__label {
        vertical-align:top;
        display: inline-block;
      }
      &__close {
        @include font-weight('bold');
        display:inline-block;
        padding: em(5px);
        cursor: pointer;
      }
    }
   
  }
  
}