.action-tools {
  padding: em($spacing__gutter);  
  
  color: $color__white;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #5E7F06;
    background-image: linear-gradient(to right,#84A20E 20%, #5E7F06);
    z-index: -1;
  }

  &__white {
    color: $color__text;

    &:before{
      background-color: inherit;
      background-image: none;
  }
}

&__gray {
  color: $color__text;

  &:before {
    background-color: $color__gray-soft;
    background-image: none;
  }
}

  &__heading {
    @include font-weight('bold');

    margin-bottom: 1em;
  }

  &__cta {
    width: 100%;
    margin-top: em($spacing__sm);
  }

  &__figure {
    font-size: em(100px);
    line-height: 1;
    vertical-align: bottom;
    margin-top: 0.25em;
    //width:50%;
    img {
      width:150px;
      height:150px;
    }
  }

  &__caption {
    @include titles-h3;
    @include font-weight('light');
    line-height: 1.4;
  }

  &__grid-option {
    & + & {
      margin-top: em($spacing__module);
    }
  }

  @include respond-to('between') {
    padding: em($spacing__md) em($spacing__md) em($spacing__md) 0;

    &:before {
      left: em($wrapper-padding--tablet) * -1;
      right: em($wrapper-padding--tablet) * -1;
      width: auto;
    }
    
    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__grid-option {
      width: calc(50% - #{em($spacing__gutter/2)});
      padding-bottom: em($spacing__sm + $spacing__md);
      position: relative;

      & + & {
        margin-top: 0;
      }
    }

    &__cta {
      position: absolute;
      bottom: 0;
    }
  }

  @include respond-to('desktop') {
    

    &:before {
      right: 0;
      // width: 150vh;
    }
  }
}
