.landing-grid{
  display:flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  &__figure {
    display:block;
    width: 30%;
    margin-right:  1em;
    flex-shrink: 0;
    position: relative;

    &:before {
      content: '';
      background-color: $color__gray-soft;
      display: block;
      width: 100%;
      position: absolute;
      
    }
  }
  &__img {
    display:block;
    max-width: 100%;
    position: relative;
  }

  &__description {
    flex-grow: 1;
  }

  &__location {
    color: $color__text-gray;
    margin-bottom: .5em;
  }

  &__read-more{
    &:after{
      content: '>';
      margin-left:0.25em;
    }
  }

  @include respond-to('between') {
    display:block;
    // max-width: em(272px);
    margin-left: auto;
    margin-right: auto;
    
    &__figure {
      width:100%;
      margin-right:  0;

    }

    &__figure + &__description {
      margin-top: 1em;
    }
  }

  @include respond-to('between') {
    &__figure {

    }
  }
}
