.related-insights {
  &__header {
    margin-bottom: em($spacing__unit);
  }

  &__cta {
    margin-top: em($spacing__sm);
  }

  @include respond-to('between') {
    &__header {
      margin-bottom: em($spacing__sm);
    }

    &__cta {
      margin-top: em($spacing__gutter);
    }
  }
}
