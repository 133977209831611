.report-scenarios {
  &__header-container {
    display:flex;
    justify-content: space-between;
  }
  &__notice {
    border: 2px dashed $color__border;
    padding: em($spacing__bt-sm);
    color: $color__text-gray;

    &-label {
      @include titles-h2;
      @include font-weight('regular');
      font-style: italic;
    }
  }

  &__create {
    
    display:block;
    width: 100%;
    border: 2px dashed $color__border;
    padding: em($spacing__bt-sm);
    //height: $chart-height;
    height: 100%;
    &-label {
      &:before {
        $circle-size: 32px;
        content: '+';
        @include font-weight('light');
        font-size: em($circle-size, $font-size__h2);
        line-height: 1;
        display: block;
        margin: 0 auto 0.5em;
        border: 1px solid $color__brand-green;
        width: em($circle-size, $circle-size);
        height: em($circle-size, $circle-size);
        border-radius: 50%;
      }
    }
  }

  &__charts {
    background-color: $color__gray-soft;
    margin: 0 em($wrapper-padding * -1);
    padding: em($spacing__module) em($wrapper-padding) ;
  }

  &__charts-column {
    & + & {
      margin-top: em($spacing__md);
    }
  }

  &__legend {
    display: flex;
    align-items: flex-end;
    padding-top: em($spacing-base);

    &-keys {
      // flex: 1 1 25%;
      font-weight: bold;

      .legend-circle {
        &::before {
          content: ' ';
          height: 15px;
          width: 15px;
          border-radius: 50%;
          margin-right: em($spacing-base/4);
          display: inline-block;
          position: relative;
          top: 5%;
        }

        &.legend-note {
          &::before {
            background: #3d89b4;
          }
        }

        &.legend-option {
          &::before {
            background: #f7941d;
          }
        }

        &.legend-preferred {
          &::before {
            background: #79478b;
          }
        }

        &.legend-common {
          &::before {
            background: #84a20e;
          }
        }
      }

      .report-scenarios__legend-column {
        padding-left: em($spacing-base/2);
        padding-right: em($spacing-base/2);
      }
    }

    &-percentages {
      flex: 1 1 auto;
      align-self: start;
      text-align: center;
      font-weight: bold;

      &.last-column {
        .report-scenarios__legend-column {
          margin-right: 0;
        }
      }
    }

    &-column {
      height: em($spacing-base*3);
      padding: em($spacing-base/2) 0;
      margin-right: em($spacing-base/4);
      line-height: 2;

      &.column-number {
        line-height: 1;
        height: em($spacing-base*2);
      }

      &:nth-of-type(2n) {
        background-color: $color__gray-soft;
      }

      &--scenario.report-scenarios__legend-column--scenario {
        background-color: $color__gray-charcoal;
        color: white;
      }

      &.chart-scenario__field {
        display: block;
        width: auto;

        .chart-scenario__field-radio {
          transform: scale(2);
        }
      }
    }

    &--liquidation {
      padding-top: 0;

      .report-scenarios__legend-column {
        // max-height: em($spacing-base*6);
        line-height: 1;
        font-size: em($spacing-base*0.8);
        height: em($spacing-base*4);
        padding: em($spacing-base) em($spacing-base/2);

        &.column-number {
          height: 2em;
        }

        .report-scenarios__legend-percent {
          font-weight: normal;
        }
      }

      // .chart-scenario__field .chart-scenario__field-radio {
      //   transform: translateY(45%) scale(2);
      // }
    }
  }

  @include respond-to('between') {
    &__charts {
      padding: em($spacing__module) em($wrapper-padding--tablet) ;
      display: flex;
      margin-left: em($wrapper-padding * -1);
      margin-right: em($wrapper-padding * -1);
    }

    &__charts-column {
      width: 33.3333%;
      padding: 0 em($wrapper-padding/2);

      &--length2 {
        width: 66.666%;
      }

      &--length3 {
        width: 100%;
      }

      & + & {
        margin-top: 0;
      }
    }

    &__notice {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 0;
      
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        //min-height: em(190px);
        padding: em($spacing__bt-sm);
        width: 100%;
        text-align: center;
      }
    }

     &__create {
      display:flex;
      //height: $chart-height--desktop;
      height: 100%;
      padding: em($spacing__bt-sm);
      text-align: center;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-start;
      

      &-label {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: em(430px, $font-size__h2--tablet);
        width: 100%;
        text-align: center;
      }
    }

   
  }

  @include respond-to('desktop') {
    &__charts {
      margin: 0;
      padding: em($spacing__module) em($spacing__bt-sm) ;
    }

    &__legend {
      &-keys {
        flex: 0 1 22%;
      }

      &-percentages {
        flex: 1 1 auto;
      }

      &--liquidation {
        .report-scenarios__legend-column {
          font-size: 1em;
        }
      }
    }
  }
}