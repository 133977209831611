.contact {
  &-form {

    &__container {
    }
    &--form {
      width: 100%;
      max-width: 40em;
      .form-input,
      .form-textarea {
        width: 100%;
        min-width: calc(100% - 2rem - 4px);
      }
      .form-input, .form-textarea, .form-select, .form-button {
        padding: 1rem;
        font-size: 1rem;
        border: solid 1px black;
        margin-bottom: 1rem;
        &.is-error {
          border-color: red;
        }
      }
      .form-row {
        padding-bottom: 1rem;
      }
      .form-input {
        margin-bottom:0;
      }
      .form-textarea {
        resize: none;
        min-height: 20em;
      }
    }
  }
}