%hide {
  display: none;
}


.global-submenu__container,
.global-header__block-right {
  @extend %hide;
}
 
.tabbed-scenarios__nav,
.valuation-input-submit,
.recommended-crosslinks,
.print-email-container,
.print-email__icon,
.liquidation-calculator__select-label,
.report-scenarios__create,
.accordion__heading,
.tool-input__error,
.tooltip,
.chart-scenario__field,
.tooltip__button,
.valuation-figure__note,
.action-tools,
.flip-card,
.page-tools,
.related-tags,
.heading-entity__figure,
.profile-details__title,
.client-brief__visit-site,
.section-content__ctalist-option,
.profile-details__visit,
.section--related-insights,
.promo-story,
.hide
 {
  @extend %hide;
}
.global-header__logo {
  width: 250px;
}
input {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
//footer links
.global-footer__utility,
.social-links,
.global-footer__menu {
  @extend %hide;
}

