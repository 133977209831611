.chart-scenario {
  text-align: center;
  &__field {
    display: inline-block;
    //margin-bottom: em($spacing__sm);
    width: 50%;
    text-align: center;
    &:only-child{
      width: 100%;
    }
    &-label,
    &-radio {
      vertical-align: middle;
    }

    &-label {
      margin-left: 0.5em;
    }
  }

  &__figures,
  &__dilution {
    text-align: left;
  }

  &__dilution {
    padding-top: em($spacing__sm);
    margin-top: em($spacing__sm);
    margin-bottom: em($spacing__sm);
    border-top: 2px solid $color__border;
    &--override {
      margin-bottom: em($spacing__bt-sm);
      padding-top: 0px;
      margin-top: 0px;
      border-top: 0px;
    }
    &-figure {
      @include font-weight('light');
      padding-top: 0.25em;
    }
  }

  &__figures {
    
    display: flex;
    flex-wrap: wrap;

    .holder-figure {
      margin-top: em($spacing__sm);
      width: 50%;
    }
    
  }

  &__wrapper{
    width: 100%;
    display: flex;
  }

  @include respond-to('between') {
    max-width: em(200px);
    margin-left: auto;
    margin-right: auto;

    &__field {
      //margin-bottom: em($spacing__gutter);

      &-radio {
        vertical-align: baseline;
      }
    }

    &__dilution {
      padding-top: em($spacing__gutter);
      margin-top: em($spacing__sm);
      &--override {
        padding-top: 0px;
        margin-top: 0px;
      }
    }

    &__figures {
      display: block;
      
      .holder-figure {
        margin-top: em($spacing__gutter);
        width: 100%;
      }
    }
    
  }
}

.am-chart {
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
  display: block;
  z-index: 10;
  &--disabled {
    display: none;
    height: 0;
  }
}