.filter-radio-group {
  $radio-size: 22px;
  
  border-bottom: 1px solid $color__border;

  &__heading {
    text-transform: uppercase;
    padding: $spacing__unit 0;
    display: inline-block;
    font-size: 1em;
  }

  &__filter-label {
    font-size: 1em;
    padding-left: em($radio-size + $spacing__unit, $font-size__small);
    display: inline-block;
    word-break: break-word;
    word-wrap: break-word; //Need for IE
    cursor: pointer;
    max-width: 100%;


    &:before {
      @include transition(border, background);

      content: '';
      position: absolute;
      left: 0;
      top: -0.1em;
      width: $radio-size;
      height: $radio-size;
      border: 1px solid $color__gray-silver;
      background-color: $color__white;
      border-radius: 50%;
      display: block;
    }
  }

  &__filter-radio {
    position: absolute;
    opacity: 0;
  }

  &__filter-radio:checked + &__filter-label {
    &:before {
      background-color: $color__brand-aqua;
      border-color: $color__brand-aqua;
    }
  }

  &__filter-option {
    position: relative;
    line-height: 1.25rem;

    @include respond-to('desktop') {
      line-height: 1.5rem;
    }

    & + & {
      margin-top: 1em;
    }
  }

  &__filters-list {
    padding: $spacing__unit 0 $spacing__sm;
  }

  @include respond-to('between') {

  }

  @include respond-to('desktop') {

  }
}
