.capital-calculator {
  &-section {
    &__panel {
      &--padded {
        padding: em($spacing__gutter);
      }
      &--one {
        margin-top:em(30px);
      }
      &__header {
        &--one {
          display:flex;
          align-items: flex-end;
        }
        &__label {
          &--one {
            margin-right: em(20px);
            align-self: center;
            display: inline-block;
          }
        }
        &__option {
          &__radio {  
            
            &--one {
              padding:em(7px) em(7px);
              flex-wrap: nowrap;
              display: flex;
              justify-content: middle;
              align-items: center;
              input {
                margin-right:em(5px);
                cursor: pointer;
              }
              &--active {
                background-color: $color__gray-soft;
              }
            }
          }
        }
      }
      &__body {
        background-color: $color__gray-soft;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: height $transition-duration $transition-easing,
        visibility 100ms linear $transition-duration;
        &--is-expanded {
          visibility: visible;
          transition: height $transition-duration $transition-easing;
        }
      }
    }
  }
}

.tool-padding {
  padding-left: 3.5em;
}