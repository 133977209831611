.section {
  &.section--recommended-crosslinks {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.section--related-insights {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.section {
  &--recommended-crosslinks {
    +.section-content__component {
      margin-top: em($spacing__module);
    }
  }
}