.accordion {
  position: relative;
  
  &__heading {
    position: relative;
    padding-right: em($wrapper-padding * 1.2);
    text-align: left;
    
  

    .icon {
      @include centeralign('vertical');
      

      right: 0;
      color: $color__black;

      &:before {
        @include transition(transform);
        
        position: relative;
        display: inline-block;
      }
    }

    &--is-expanded {
      .icon {
        &:before {
          transform: rotateX(180deg);
        }
      }
    }
  }

  &__body {
    overflow: hidden;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    transition: height $transition-duration $transition-easing,
      visibility 100ms linear $transition-duration;

    &--is-expanded {
      visibility: visible;
      transition: height $transition-duration $transition-easing;
    }
  }

  &--justified-heading &{
    &__heading {
      width: 100%;
    }
  }

  &--rotate-x &{
    &__heading {
      &--is-expanded {
        .icon {
          &:before {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}