.video-embed  {
  position: relative;
  border-bottom: 1px solid $color__border--darker;
  border-width: 1px 0;
  padding: 1em 0;

  &__player {
    max-width: em(500px);
    &-wrapper {
      $ratio: 315 / 560 * 100%;
      position: relative;
      width: 100%;
      padding-bottom: $ratio;
      position: relative;
    }

    iframe, object {
      max-width: 100%;
      position: absolute;
      width: 100%;
      height: 100% !important;
    }
  }

  &__title {
    margin-top: 1em;
    display: block;
  }

  &__cta {
    margin-top: em($spacing__sm);
  }

  &--grid &{  
    &__container {
      margin-bottom:  em($spacing__gutter);
    }
  }

  @include respond-to('between') {
    &__container {
      display: flex;
      flex-wrap: nowrap;
    }

    &__player {
      flex-shrink: 0;
      width: 49%;
      max-width: em(420px);
      margin-right: 1em;
    }

    &__title {
      flex-grow: 1;
      margin-top: 0;
    }

    &__cta {
      width: 100%; 
      margin-top: em($spacing__gutter);
    }

    &--grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &--grid &{
      
      &__container {
        width: calc(50% - 0.5em);
        margin-bottom: 1em;
        flex-wrap: wrap;

        &:first-child:last-child {
          flex-grow: 1;
          width: 100%;
        }
      }

      &__player {
        width: 100%;
        margin-right: 0;
      }

      &__title {
        margin-top: 1em;
        font-size: em($font-size__h2--tablet);
      }
    }
  }
}