.dictionary-listing {
  &__option {
    border-bottom: 1px solid $color__border--darker;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: em($spacing__unit * 2) 0;

    &:first-child {
      border-top: 1px solid $color__border--darker;
    }
  }

  &__category{
    width: em(50px, $font-size__heading);
    margin-right: 0.2em;
    flex-shrink: 0;
  }

  &__body {
    flex-grow: 1;
  }

  &__deal {
    & + & {
      margin-top: em($spacing__sm);
    }
  }

  &__description {
    margin-top: 0.5em;
  }


  &__meta-item {
    @include font-weight('bold');
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
  }

  @include respond-to('between') {

    &__deal {
      & + & {
        margin-top: em($spacing__gutter);
      }
    }
  }
}
