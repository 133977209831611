.pageheading {
  @include bg-cover;
  background-position: 50%;
  background-color: $color__gradient-orange--start;
  background-image: linear-gradient(to right, $color__gradient-orange--start, $color__gradient-orange--end);
  position: relative;
  padding: em($spacing__gutter) 0;
  z-index: $zindex__pageheading;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./../images/cube-white.png')  0 0;
    opacity: 0.15;
    z-index: 1;
  }

  &:after {
    content: '';
    background-image: linear-gradient(to right, $color__gradient-orange--start, rgba($color__gradient-orange--start, 0) );
    display: block;
    position: absolute;
    width: 30%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  &--background {
    &:after,
    &:before {
      content: none;
    }
  }

  &__body {
    position: relative;
    z-index: 5;
  }

  &__heading {
    color: $color__white;
  }

  &__description {
    margin-top: em(20px);
    color: $color__white;
  }
  
  @include respond-to('between') {
    padding: em($spacing__module) 0 em($spacing__module);
  }
}
