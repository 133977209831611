.valuation-figure {
  &__note {
    @include font-weight('regular');
    color: $color__text-gray;
    font-style: italic;
    font-size: rem($font-size__h3);
  }

  &__label {
    //@include font-weight('semibold');
    margin-bottom: 0.25em;
    font-size:rem($font-size__h3);
  }

  &__value  {
    @include titles-h3;
    @include font-weight('regular');

    &--large {
      @include titles-h1;
      @include font-weight('light');
      font-weight:em(51px);
    }
  }
}