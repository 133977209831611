.readings {
  &__option {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: em($spacing__unit * 2) 0;
    padding-right: 1em;
  }

  &__figure {
    width: em(125px);
    margin-right: 1em;
    flex-shrink: 0;

    > img {
      max-width: 100%;
      display: block;
    }
  }

  &__body {
    flex-grow: 1;
  }

  &__meta-item {
    @include font-weight('bold');
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
  }
  &__title {
    font-weight: 700;
  }
  &__description {
    font-size: em($font-size__small);
    margin-bottom:2em;
  }

  @include respond-to('between') {
    &__option {
      padding-right: 2em;

      &:after {
        font-size: em(26px);
      }
    }

    &__figure {
      width: em(150px);
      margin-right: em($spacing__sm);
    }

    &__figure + &__body &{
      &__link {

        max-width: em(600px, $font-size__display-3--tablet);
      }
    }

  }
  @include respond-to('desktop') {
    //padding-left:7em;
  }
}
