.projectphase {
  &--theme-purple {
    .linked-list__link {
      color: $color__purple-light;

      &:hover {
        color: $color__white;
      }
    }

    .content-block__cta-link:after, 
    .content-block__cta-strong {
      color: $color__purple-light;
    }

    .scrollspy-nav__link--is-active  {
      color: $color__brand-purple;
    }
  }
  
  // Vue Transition Effects
  &__effect-enter-active, &__effect-leave-active {
    transition: opacity .5s ease;
  }
  &__effect-enter, &__effect-leave-active {
    opacity: 0
  }
  

  &__dummy {
    position: absolute;
    background-color: transparent
  }

  &__main {
    color: $color__white;
    padding: em($spacing__module) 0 em($spacing__gutter);
    background-color: $color__gunmetal;
  }

  &__sticky-svg {
    display: none;
  }

  &__sidebar {
    padding: em($global-header-height + $spacing__gutter) 0 em($spacing__lg);
  }
  
  .scrollspy-nav {
    top: em($global-header-height);
  }

  &__cta {
    width: 100%;
    text-align: left;
    margin-top: 2em;
  }
  
  @include respond-to('between') {
    &__sidebar {
      padding: em($global-header-height--tablet + $spacing__module) 0 em(120px);
    }
    &__main {
      color: $color__white;
      padding: em($spacing__module--between) 0 em($spacing__module);
    }

    .scrollspy-nav {
      top: em($global-header-height--tablet);
    }
  }

  @include respond-to('desktop') {
    display: flex;

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    &__effect-enter-active {
      transition: transform 1s ease-out;
      opacity: 1;

      .scrollspy-nav {
        transition: opacity 1s linear 0.5s;
        opacity: 1;
      }

      .projectphase__main {
        &:before {
          transition: opacity 1s linear 1s;
          opacity: 1;
        }
      }
      
    }
    &__effect-leave-active {
      transition: transform .5s ease-in 0.1s;
      opacity: 1;

      .scrollspy-nav {
        transition: opacity 0.3s;
      }

      .projectphase__main {
        &:before {
          transition: opacity 0.3s;
        }
      }
    }

    &__effect-enter,
    &__effect-leave-to
      /* .slide-fade-leave-active below version 2.1.8 */ {
      transform: translateX(100%);

      .scrollspy-nav {
        opacity: 0;
      }

      .projectphase__main {
        &:before {
          opacity: 0;
        }
      }

    }

    .scrollspy-nav {
      top: auto;
      transition: opacity 1s linear 0.5s;
    }
    
    
    &__sidebar {
      background-color: $color__white;
      width: 50%;
      flex-shrink: 0;
      padding-top: em($global-header-height--tablet + $spacing__module--between);
    }
    
    &__main {
      padding: em($global-header-height--tablet + $spacing__gutter) 0 em($spacing__module--between);
      flex-grow: 1;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        height:  em($global-header-height--tablet);
        display: block;
        position: fixed;
        top: 0;
        background-color: $color__gunmetal;
        z-index: 10;
        right: 0;
        left: 50%;
        opacity: 1;
      }
    }

    .scrollspy-nav {

      position: fixed;
      padding-bottom: em($spacing__module--between);

      &--stick-bottom {
        position: absolute;
        bottom: 0;
      }
    }
  }

  @include respond-to('desktop-large') {

    &__sidebar {
      padding-left: em($wrapper-padding--tablet)
    }
  }
}