.formfields-grid {
  &__item {
    & + & {
      margin-top: em($spacing__sm);
    }
  }


  @include respond-to('landscape') {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    //align-items: flex-end;
      
    &--halfs {
      justify-content: space-between;
    }

     &__item {
      & + & {
        margin-top: 0;
      }
    }
    

    &--halfs &{
      &__item {
        width: 50%;
        margin-bottom: em($spacing__gutter);
        
        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
      }
    }

    &--thirds &{
      &__item {
        width: 33.3333%;
        margin-bottom: em($spacing__gutter);
        
        &:last-child,
        &:nth-last-child(2),
        &:nth-last-child(3) {
          margin-bottom: 0;
        }
      }
    }

    &--column-spaced &{
      &__item {
        margin-right: em($spacing__sm);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__grid-item {
      & + & {
        margin-top: 0;
      }
    }
  }
}