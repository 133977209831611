@mixin titles-h1 () {
  @include font-weight('semibold');

  font-size: px-to-em($font-size__h1);
  line-height: 1.1;


  @include respond-to('desktop') {
    font-size: px-to-em($font-size__h1--tablet);
  }
}

@mixin titles-h2 ($spacing: false) {
  @include font-weight('bold');
  
  font-size: px-to-em($font-size__h2);
  line-height: 1.1;

  @if $spacing {
    margin-bottom: px-to-em($spacing__unit, $font-size__h2);
  }

   @include respond-to('between') {
    font-size: px-to-em($font-size__h2--tablet);

    @if $spacing {
      margin-bottom: px-to-em($spacing__unit, $font-size__h2);
    }
  }
}

@mixin titles-h3 () {  
  @include font-weight('regular');
  line-height: 1.1;
  font-size: px-to-em($font-size__h3);
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__h3--tablet);
  }
}

@mixin titles-h4 () {  
  @include font-weight('regular');
  line-height: 1.2;
  font-size: px-to-em($font-size__h4);
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__h4--tablet);
  }
}

@mixin titles-h5 () {  
  @include font-weight('bold');
  line-height: 1.2;
  font-size: px-to-em($font-size__h5);
}


// Display Heading
@mixin titles-display1 () {
  @include font-weight('bold');
  
  font-size: px-to-em($font-size__display-1);
  line-height: 1.2;
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__display-1--tablet);
  }

  @include respond-to('desktop') {
    font-size: px-to-em($font-size__display-1--desktop);
  }
}

@mixin titles-display2 () {
  @include font-weight('semibold');
  
  font-size: px-to-em($font-size__heading);
  line-height: 1.05;
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__heading--tablet);
  }
}

@mixin titles-display3 () {
  @include font-weight('semibold');
  
  font-size: px-to-em($font-size__display-3);
  line-height: 1.2;
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__display-3--tablet);
  }
}

@mixin titles-display4 () {
  @include font-weight('semibold');
  
  font-size: px-to-em($font-size__display-4);
  line-height: 1.2;
  
  @include respond-to('between') {
    font-size: px-to-em($font-size__display-4--tablet);
  }
}

.titles--h1 {
  @include titles-h1;
}

.titles--h2 {
  @include titles-h2;
}

.titles--h2-regular {
  @include titles-h2;
  @include font-weight('regular');
}


.titles--h2-spaced {
  @include titles-h2(true);
}

.titles--h3 {
  @include titles-h3;
}

.titles--h3-light {
  @include titles-h3;
  @include font-weight('light');
}

.titles--h4 {
  @include titles-h4;
}

.titles--h4-light {
  @include titles-h4;
  @include font-weight('light');
}

.titles--h4-semibold {
  @include titles-h4;
  @include font-weight('semibold');
}

.titles--h5 {
  @include titles-h5;
}

.titles--display1 {
  @include titles-display1;
}

.titles--display2 {
  @include titles-display2;
}
.titles--display2-light {
  @include titles-display2;
  @include font-weight('light');
}

.titles--display3 {
  @include titles-display3;
}
.titles--display3-light {
  @include titles-display3;
  @include font-weight('light');
}

.titles--display4 {
  @include titles-display4;
}

.titles--eyebrow {
  @include font-weight('bold');
  font-size: em($font-size__small);
  text-transform: uppercase;
  line-height: 1.05;
}