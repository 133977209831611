.grid {
  &__item {
    & + & {
      margin-top: em($spacing__gutter);
    }
  }


  @include respond-to('between') {
    $grid-size: 50%;
    $grid-spacing: em($spacing__gutter);

    display: flex;
    flex-wrap: wrap;
    margin: 0 $grid-spacing * -0.5;
    position: relative;
    
    &__item {
      width: calc(#{$grid-size} - #{$grid-spacing});
      margin: 0 $grid-spacing/2 $grid-spacing;

      &:nth-child(n+3) {
        margin-top: $grid-spacing;
      }

      & + & {
        margin-top: 0
      }
    }

    &--with-lines & {
      &__item {
        &:nth-child(n+3) {
          margin-top: $grid-spacing*2;
        }

        &:nth-child(2n+3) {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            margin-top: $grid-spacing * -1.5;
            width: calc(200% + #{$grid-spacing});
            backface-visibility: hidden;
            border-top: 1px solid $color__border--darker;
          }
        }
      }
    }
  }

  @include respond-to('desktop-large') {
    $grid-size: 33.333%;
    $grid-spacing: em($spacing__gutter);

    &__item {
      width: calc(#{$grid-size} - #{$grid-spacing});
      margin: 0 $grid-spacing/2 $grid-spacing;

      &:nth-child(n+3) {
        margin-top: 0;
      }

      &:nth-child(n+4) {
        margin-top: $grid-spacing;
      }
    }

    &--with-lines & {
      &__item {
         // Revert Tablet Styles
        &:nth-child(n+3) {
          margin-top: 0;
        }

        &:nth-child(2n+3) {        
          position: static;

          &:before {
            content: none;
          }
        }

        &:nth-child(n+4) {
          margin-top: $grid-spacing*2;
        }

        &:nth-child(3n+4) {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            margin-top: $grid-spacing * -1.5;
            width: calc(300% + #{$grid-spacing * 2});
            backface-visibility: hidden;
            border-top: 1px solid $color__border--darker;
          }
        }
      }
    }
  }
}
