/* Variables */
$icomoon-font-path: "./../icons/" !default;

$ico-icon-name: "workbench";

$icomoon-hash: tik3e1;

//Mixin to load icon font
@mixin icon-face-family($font-family, $file-path, $font-weight:normal, $font-style: normal) {
  @font-face {
  	font-family: $font-family;
  	src:  url('#{$file-path}#{$font-family}.ttf') format('truetype'),
					url('#{$file-path}#{$font-family}.woff') format('woff'),
					url('#{$file-path}#{$font-family}.svg?z3e1ry##{$file-path}') format('svg');
		font-weight: $font-weight;
		font-style: $font-style;
  }
}

//sass mappings
$icons: (
  youtube: "\e901",
  linkedin: "\e902",
  facebook: "\e903",
  twitter: "\e904",
  search-bold: "\f003",
  plus-bold: "\f01c",
  minus-bold: "\f022",
  chevron-up--bold: "\f095",
  chevron-down--bold: "\f096",
  chevron-left--bold: "\f097",
  chevron-right--bold: "\f094",
  arrow-up--bold: "\f09c",
  arrow-right--bold: "\f09d",
  close: "\f00c",
  share: "\f045",
  search: "\f002",
  plus: "\f01b",
  minus: "\f021",
  chevron-up: "\f090",
  chevron-down: "\f091",
  chevron-left: "\f092",
  chevron-right: "\f093",
  arrow-up: "\f098",
  arrow-down: "\f099",
  arrow-left: "\f09a",
  arrow-right: "\f09b",
  long-arrow-up: "\f0aa",
  long-arrow-down: "\f0ab",
  graph: "\e905",
  folder: "\e906",
  dots: "\e900",
  bookmark: "\e909",
  mail: "\e908",
  bookmark-wide: "\f059"
);

// Load Icon
@include icon-face-family($ico-icon-name, $icomoon-font-path);

//attribute selector of class name for icons
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $ico-icon-name !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


//Generate Class for each icon based on sass mapping object
@each $name, $icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}