
$color__brand-chile: #D14925;
$color__brand-orange: #F7941D;
$color__brand-purple: #79478B;
$color__brand-green: #84A20E;
$color__brand-blue: #3D89B4;
$color__brand-aqua: #27B0B1;

$color__yellow: #FBD913;
$color__black: #000;
$color__black-light: #191d1f;
$color__gunmetal: #2D3539;
$color__blue-pelorous: #248ECB;
$color__purple-light: #ADB9E3;
// $color__blue-curious: #3D89B4;

$color__white: #fff;


$color__gray: #9B9B9B;
$color__gray-soft: #EFF0ED;
$color__gray-light: #ECECEC;
$color__gray-silver: #B5B5B5;
$color__gray-dim: #696969;
$color__gray-athens: #D6D7D8;
$color__gray-aluminium: #868D91;
$color__gray-charcoal: #3F3F3F;
$color__gray--f7: #F7F7F6;
$color__gray--fc: #FCFCFC;
$color__gray--f3: #F3F4F2;
$color__gray--f8: #F8F8F8;


$color__white--opaque: rgba(255, 255, 255, 0.95);
$color__black--opaque: rgba(45, 53, 57, 0.75);
$color__red--opaque: rgba(209, 73, 37, 0.75);
$color__dropshadow: rgba(0, 0, 0, 0.5);

$color__gradient-orange--start: #F79621;
$color__gradient-orange--end: #F67300;

$color__gradient-aqua--start: #3D89B4;
$color__gradient-aqua--end: #27B0B1;

$color__gradient-purple--start: #79478B;
$color__gradient-purple--end: #b89ec1; 

$color__gradient-green--start: #83A10F;
$color__gradient-green--end: #7B9A0C;

$color__dark_green: #62830a;
$color__tool-green-light: #CFE73D;
$color__tool-aqua-haze: #CCD4C9;

// Mappings
$color__text: $color__gunmetal;
$color__text-gray: $color__gray-aluminium;
$color__border: $color__gray-silver;
$color__border--darker: $color__gray-dim;

$color__link: $color__brand-orange;
$color__link--hover: $color__brand-chile;
$color__link--hover-dark: $color__white;

