.subscribe-callout {
  border-top: 1px solid $color__border--darker;
  padding-top: em($spacing__sm);


  &__heading {
    margin-bottom: 1.2em;
  }
  
  @include respond-to('between') {
    padding-top: em($spacing__gutter);
  }
}
