.crosslink-callout {
  border-top: 1px solid $color__border--darker;
  padding-top: em($spacing__sm);
  display:flex;
  flex-wrap: nowrap;

  & + & {
    margin-top: em($spacing__gutter);
  }

  &__figure {
    width: em(100px);
    flex-shrink: 0;
    margin-right: 1em;
    text-align: center;

    > img {
      max-width: 100%;
    }
  }

  &__body {
    flex-grow: 1;
  }

  &__heading {
    @include font-weight('bold');

    display:block;
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
    margin-bottom: 2em;
  }

  &__title {
    margin-bottom: 1.2em;
  }

  &__cta {
    margin-top: em($spacing__sm);
  }
  
  @include respond-to('between') {
    padding-top: em($spacing__gutter);

     &__figure {
      width: em(200px);
     }

  }
}
