.pages {
  &--homepage {
    .global-header {
      position: absolute;
    }
  }

  @include respond-to('between') {
    &--homepage {
      .global-footer {
        margin-bottom: 0;
      }

      // .global-header {
      //   position: absolute;
      // }
    }
  }
}
