.articles-listing {
  &__link {
    font-size: em(20px)
  }
  &__option {
    border-bottom: 1px solid $color__border--darker;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    padding: em($spacing__unit * 2) 0;
    &:first-child {
      border-top: 1px solid $color__border--darker;
    }
  }

  &__figure {
    width: em(165px);
    margin-left: em(20px);
    flex-shrink: 0;

    > img {
      max-width: 100%;
      display: block;
    }
  }

  &__body {
    flex-grow: 1;
  }
  &__meta {
    margin-bottom: em(15px);
  }
  &__meta-item {
    line-height: 1.05;
  }

  @include respond-to('between') {
    &__option {

      &:after {
        font-size: em(26px);
      }
    }

    &__figure {
      width: em(150px);
    }

    &__figure + &__body &{
      &__link {

        max-width: em(600px, $font-size__display-3--tablet);
      }
    }

    &__link {
      font-size: em(22px);
      max-width: em(700px, $font-size__display-3--tablet);
      display: inline-block;
    }
  }
  @include respond-to('desktop') {
    &__link {
      font-size: em(22px)
    }
    &__figure {
      margin-left: em(30px);
    }
  }
}
