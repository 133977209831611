.hide {
  display:none;
}
.opaque-overlay{
  &:after {
    content: " ";
    z-index: 12;
    display: block;
    position: fixed;
    height: 100vh;
    width:100vw;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }
}