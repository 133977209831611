.flip-card {
  display: block;
	margin: 0 auto;
  color: $color__white;
  position: relative;
  

  &__frame {
    
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    perspective: em(1000px);
  }
  
  &__front,
  &__back {
     @include transition(transform 0.5s);
    display: block;
    position: absolute;
		top: 0;
		left: 0;
		min-width: 100%;
		min-height: 100%;
    backface-visibility: hidden;
    background-color: $color__gray-soft;
    z-index: 2;
  }

  &__front {
    // transform-style: preserve-3d;
    z-index: 2;
    transform: rotateY(0);
  }

  &__back {
    // transform-style: preserve-3d;
    transform: rotateY(180deg);
    background-color: $color__gray-dim;
    padding: em($spacing__sm);
    padding-right: em($spacing__sm + 5px);
    text-align: left;
    overflow: auto;
  }

  &__close {
    position: absolute;
    top: em($spacing__sm/1.5);
    right: em($spacing__sm/1.5);
    color: $color__white;

    @include ico-icon-after('close') {
      
    }
  }

  &--hover &{
    &__front {
      transform: rotateY(-180deg);
    }

    &__back {
      transform: rotateY(0deg);
    }
  }

  
  @include respond-to('between') {
    &:hover & {
      &__front {
        transform: rotateY(-180deg);
      }

      &__back {
        transform: rotateY(0deg);
      }
    }
  }
}