.content-block {
  $icon-size: 18px;

  & + & {
    margin-top: em($spacing__gutter);
  }

  &__title {
    margin-bottom: em($spacing__unit/2, $font-size__h1);
  }

  &__cta-action {
    margin-top: em($spacing__sm);
    text-align: right;
  }

  &__component {
    margin-top: em($spacing__sm);
    padding-bottom: em($spacing__sm);
    border-bottom: 1px solid $color__border--darker;

    &-border {
      border-top: 1px solid $color__border;
    }

    &--border-dark {
      padding-top: em($spacing__sm);
      border-top: 1px solid $color__border--darker;
    }

    &--border-none{
      border-top: none;
      border-bottom: none;
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &__cta-link {
    @include font-weight('light');

    position: relative;
    display: inline-block;
    padding-right: em($spacing__unit + $icon-size);

    @include ico-icon-after('chevron-right') {
      
      color: $color__brand-orange;
      font-size: em($icon-size);
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      padding-top: 0.2em;
      padding-left: 1em;
    }
  }

  &__cta-strong {
    color: $color__link;
    display: inline-block;
  }

  &__infographic {
    display: flex;
    flex-wrap: nowrap;

    &-figure {
      width: 20%;
      flex-shrink: 0;
      align-self: center;
      margin-right: em($spacing__sm);
    }

    &-image {
      max-width: 100%;

    }
  }

  &__video-figure {
    margin: em($spacing__unit) 0;
  }

  &__video-headline {
    font-size: em($font-size__h3);
  }

  @include respond-to('between') {
    &__cta-link {
      font-size: em($font-size__h4--tablet);
    }

    &__cta-action {
      margin-top: em($spacing__bt-sm)
    }

    &__video-headline {
      font-size: em($font-size__h3--tablet);
    }
  }
}
