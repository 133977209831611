.document-driver {

  padding-left:0;
  &__form {
    margin-top:em(30px);
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    &__input {
      margin-right: em(75px);
      margin-top: em(10px);
      position:relative;
      box-sizing: content-box;
      @include ico-icon-after('chevron-down') {
        @include centeralign(vertical);
        position: absolute;
        right: 1em;
        top:65%;
        pointer-events: none;
      }
    }

    &__label {
      display: block;

    }

    &__select {
      margin-top:em(5px);
      width: em(300);
      background: transparent;
      border: none;
      background-color: $color__gray-light;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      padding: em(10);
      text-align: left;
      padding-left: 1em;
      padding-right: 2em;
      position: relative;

    
    }
    &__button {
      display:inline-block;
      margin: em(40) 0;
      position:relative;
    }
  }
  .btn-cta {

    font-size:em(20);
    color:$color__gray-light;
    &:hover:not(.btn-cta--active) {
      background-color:transparent;
      color:$color__gray-light;
    }

    &--active {
      color:$color__brand-green
    }
  }
  @include respond-to ('desktop') {
    //padding-left:7em;
  }
}