//------------------------------------------
//				Zindexes
//------------------------------------------

$zindex__modal: 200;

$zindex__global-header: 100;

$zindex__loading-screen: $zindex__global-header + 10;

$zindex__global-nav: $zindex__global-header - 10;

$zindex__sticky-content: 20;

$zindex__pageheading: 11;
