.underline {
  @include respond-to('between') {
    &:after {
      @include transition(width);
  
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom:0;
      width: 0%;
      height: rem(1px);
      background-color:#fff;
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  
}