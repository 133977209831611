$font-family-goodwin-1-color: 'Goodwin-1-Color';
$font-family-goodwin-2-color: 'Goodwin-2-Color';
$font-icon-weight-regular: 400;

@include font-face($font-family-goodwin-1-color, 'goodwin-1-color', $font-icon-weight-regular, normal);
@include font-face($font-family-goodwin-2-color, 'goodwin-2-color', $font-icon-weight-regular, normal);

%icon-base {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%icon-2-color {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-family-goodwin-2-color !important;
    @extend %icon-base;
}

%icon-1-color {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-family-goodwin-1-color !important;
    @extend %icon-base;
}

// 2 color icons

.icon-goodwin-search-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e100a";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e100b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-search-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e101b";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e101c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-email-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e102a";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e102b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-email-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e103b";
	    color: rgb(255, 255, 255);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e103c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-call-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e104a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e104b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-call-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e105b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e105c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-vcard-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e106a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e106b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-vcard-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e107b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e107c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-follow-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e108a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e108b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-follow-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e109b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e109c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-linkedin-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e110a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e110b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-linkedin-b {

	&:before {
		@extend %icon-1-color;
	    content: "\e215";
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-twitter-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e112a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e112b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-twitter-b {

	&:before {
		@extend %icon-1-color;
	    content: "\e216";
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-facebook-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e114a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e114b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-facebook-b {

	&:before {
		@extend %icon-1-color;
	    content: "\e217";
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-instagram-b {

	&:before {
		@extend %icon-1-color;
	    content: "\e221";
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-wechat-b {

	&:before {
		@extend %icon-1-color;
	    content: "\e900";
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-volume-medium {

	&:before {
		@extend %icon-1-color;
		content: "\ea27";
		color: rgb(255, 255, 255);
	}
}

.icon-goodwin-phone-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e116a";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e116b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-phone-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e117b";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e117c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-fax-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e144a";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e144b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-fax-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e145b";
	    color: rgb(248, 149, 36);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e145c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-awards-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e118a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e118b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-awards-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e119b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e119c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-languages-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e120a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e120b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-languages-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e121b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e121c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-news-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e122a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e122b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-news-b {

	&:before {
		@extend %icon-2-color;
	    content: "\123b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\123c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-education-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e124a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e124b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-education-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e125b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e125c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-admissions-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e126a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e126b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-admissions-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e127b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e127c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-circle-checkbox {
	&:before {
		@extend %icon-2-color;
	    content: "\e126a";
	    color: transparent;
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e126b";
	    color: rgb(45, 53, 57);
	    color: $color__gray-dim;
	}
}

.icon-goodwin-circle-checkbox-selected {
	&:before {
		@extend %icon-2-color;
	    content: "\e126a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e126b";
	    color: $color__gray-dim;
	}
}


.icon-goodwin-clerkship-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e128a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e128b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-clerkship-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e129b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e129c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-bar-association-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e130a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e130b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-bar-association-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e131b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e131c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-events-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e132a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e132b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-events-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e133b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e133c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-people-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e134a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e134b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-people-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e135b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e135c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-offices-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e136a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e136b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-offices-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e137b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e137c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-services-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e138a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e138b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-services-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e139b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e139c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-careers-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e140a";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e140b";
	    margin-left: -1em;
	    color: rgb(45, 53, 58);
	}
}

.icon-goodwin-careers-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e141b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e141c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-geolocation-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e142a";
	    color: rgb(45, 53, 58);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e142b";
	    margin-left: -1em;
	    color: rgb(248, 149, 34);
	}
}

.icon-goodwin-geolocation-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e143b";
	    color: rgb(248, 149, 34);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e143c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-up-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e150a";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e150b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-up-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e151b";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e151c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-down-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e152a";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e152b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-down-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e153b";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e153c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-left-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e154a";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e154b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-left-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e155b";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e155c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-right-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e156a";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e156b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-right-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e157b";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e157c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-up-down-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e158a";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e158b";
	    margin-left: -1em;
	    color: rgb(45, 53, 57);
	}
}

.icon-goodwin-up-down-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e159b";
	    color: rgb(246, 143, 47);
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e159c";
	    margin-left: -1em;
	    color: rgb(255, 255, 255);
	}
}

.icon-goodwin-globe-a {

	&:before {
		@extend %icon-2-color;
	    content: "\e900";
	    color: rgb(246, 143, 47);
	    vertical-align: middle;
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e901";
	    margin-left: -1.0400390625em;
	    color: rgb(45, 53, 57);
	    vertical-align: middle;
	}
}

.icon-goodwin-globe-b {

	&:before {
		@extend %icon-2-color;
	    content: "\e902";
	    color: rgb(246, 143, 47);
	    vertical-align: middle;
	}
	&:after {
		@extend %icon-2-color;
	    content: "\e903";
	    margin-left: -1.0400390625em;
	    color: rgb(255, 255, 255);
	    vertical-align: middle;
	}
}

// 1 color icons


.icon-goodwin-menu {

	&:before {
		@extend %icon-1-color;
    	content: "\e200";
    }
}
.icon-goodwin-arrow-down {

	&:before {
		@extend %icon-1-color;
    	content: "\e201";
    }
}
.icon-goodwin-arrow-left {

	&:before {
		@extend %icon-1-color;
    	content: "\e202";
    }
}
.icon-goodwin-arrow-right {

	&:before {
		@extend %icon-1-color;
    	content: "\e203";
    }
}
.icon-goodwin-arrow-up {

	&:before {
		@extend %icon-1-color;
    	content: "\e204";
    }
}
.icon-goodwin-close {

	&:before {
		@extend %icon-1-color;
    	content: "\e205";
    }
}
.icon-goodwin-expand {

	&:before {
		@extend %icon-1-color;
    	content: "\e206";
    }
}
.icon-goodwin-collapse {

	&:before {
		@extend %icon-1-color;
    	content: "\e207";
    }
}
.icon-goodwin-video-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\208";
    }
}
.icon-goodwin-link-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e209";
    }
}
.icon-goodwin-print-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e210";
    }
}
.icon-goodwin-blog-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e211";
    }
}
.icon-goodwin-share-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e212";
    }
}
.icon-goodwin-pdf-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e213";
    }
}
.icon-goodwin-geolocation-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e214";
    }
}
.icon-goodwin-linkedin-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e215";
    }
}
.icon-goodwin-twitter-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e216";
    }
}
.icon-goodwin-facebook-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e217";
    }
}
.icon-goodwin-star-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e218";
    }
}
.icon-goodwin-play-1c {

	&:before {
		@extend %icon-1-color;
    	content: "\e219";
    }
}

