.rte {
  line-height: 1.5;

  &--top-padded {
    padding-top: 1em;
  }
  
  &--light {
    @include font-weight('light');
  }
  &--large {
    font-size: em(18px);
  }
  &--theme-dark {
    color: $color__white;
  }

  &--small {
    font-size: px-to-em($font-size__small);
    line-height: 1.4;
  }

  &--lead {
    font-size: em($font-size__copy-lead);
    line-height: 1.4;
  }

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: $color__blue-pelorous;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.1em;
    margin-bottom: px-to-em($spacing__normalize);

    ul {
      list-style-type: circle;
      margin-top: px-to-em($spacing__normalize/2);
    }

  }

  li {
    margin-bottom: px-to-em($spacing__unit/2);
  }

  ol {
    padding-left: 1.1em;
    list-style-type: decimal;
    margin-top: px-to-em($spacing__unit/2);
    margin-bottom: px-to-em($spacing__normalize);

    ol {
      margin-top: px-to-em($spacing__normalize/2);
    }
  }

  blockquote,
  .blockquote {
    @include font-weight('light');
    font-size: em($font-size__blockquote);
    line-height: 1.2;
    margin: px-to-em($spacing__normalize) 0;
    padding-top: em($spacing-base, $font-size__blockquote);
    border-top: 3px solid $color__brand-aqua;

    p {
      margin-bottom: 0.5em;
    }

    cite,
    .cite {
      font-size: em($font-size__small, $font-size__blockquote);
      text-transform: uppercase;
    }
  }

  .blockquote{
    display: block;
    margin-block-start: em($spacing-base);
    margin-block-end: em($spacing-base);
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  cite,
  .cite {
    @include font-weight('bold');

    font-style: normal;
    font-size: em($font-size__small);
    color: $color__text-gray;
  }

  //headings
  h2,
  .h2-size {
    font-size: px-to-em($font-size__h2);
    margin-bottom: px-to-em($spacing__unit, $font-size__h2);
  }

  h3,
  .h3-size {
    font-size: px-to-em($font-size__h3);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h3);
  }

  h4,
  .h4-size {
    font-size: px-to-em($font-size__h4);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h4);
  }

  h5,
  .h5-size {
    font-size: px-to-em($font-size__h5);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h5);
  }
  
  h6,
  .h6-size {
    font-size: px-to-em($font-size__h6);
    margin-bottom: px-to-em($spacing__normalize, $font-size__h6);
  }  

  img,
  iframe {
    max-width: 100%;
  }

  table {
    background-color: $color__gray--f3;
    margin: px-to-em($spacing__normalize * 2) 0;

    tr {
      border-bottom: 1px solid $color__border;
      &:last-child {
        border-bottom: 0;
      }
    }

    th, .th {
      @include font-weight('regular');

      background-color: $color__gunmetal;
      //text-transform: uppercase;
      font-size: rem($font-size__display-4);
      color: $color__white;
      padding: em($spacing__sm, $font-size__display-4);
      text-align: left;

      &:first-child {
        width: 30%;
        text-align: left;
      }
    }

    td {
      padding: em($spacing__sm);

      &:first-child {
        color: $color__gray-aluminium;
        text-align: left;
      }
    }
  }

  @include respond-to('between') {
    font-size: px-to-em($font-size__copy--desktop);

    &--large {
      font-size: em(20px);
    }
    &--lead {
      font-size: em($font-size__copy-lead--tablet);
    }

    &--small {
      font-size: em($font-size__small);
    }

    a {
      &:hover {
        color:$color__gray;
      }
    }

    cite,
    .cite {
      font-size: em($font-size__small);
    }

    blockquote,
    .blockquote {
      font-size: em($font-size__blockquote--tablet, $font-size__copy--desktop);
      margin-left: em($spacing__lg ,$font-size__blockquote--tablet);

      cite,
      .cite {
        font-size: 0.5em;
      }
    }

    blockquote, .blockquote p::before {
      content: '\201C';
    }
    blockquote, .blockquote p::after {
      content: '\201D';
    }
    h2,
    .h2-size {
      margin-bottom: px-to-em($spacing__unit, $font-size__h2--tablet);
    }

    h3,
    .h3-size {
      font-size: px-to-em($font-size__h3--tablet);
      margin-bottom: px-to-em($spacing__unit, $font-size__h3--tablet);
    }

    h4,
    .h4-size {
      font-size: px-to-em($font-size__h4--tablet);
      margin-bottom: px-to-em($spacing__unit, $font-size__h4--tablet);
    }

    table {
      
      th, .th {
        font-size: rem(18px);
      }
      th, .th {
        &:first-child {
          width: 30%;
          padding: em($spacing__sm, $font-size__display-4);
          padding-left: rem(50px);
          color: $color__white;
        }
      }

      td {
        padding: em($spacing__sm, 20px);
   
        font-size: rem($font-size__copy--desktop);
        vertical-align: top;
        
        &:first-child {
          font-size: 1em;
          color: $color__gray-aluminium;
          text-align: left;
          line-height: 1.8;
          padding: em($spacing__sm, $font-size__copy--desktop);
          padding-left: rem(50px);
        }
      }
    }

    .theme-aqua &, .theme-orange &, .theme-purple &{
      a {
        &:hover {
          color:$color__gray;
        }
      }
    }
  }
}