.section-content {
  $icon-size: 18px;

  & + & {
    margin-top: em($spacing__gutter);
  }

  &__component {
    & + & {
      margin-top: em($spacing__sm);
    }

    & + &--spaced,
    &--spaced + &--spaced { 
      margin-top: em($spacing__module);
    }
  }

  &__ctalist-option {
    & + & {
      margin-top: 1em;
    }
  }

  &__header{
    margin-bottom: em($spacing-base);
    padding-bottom: em($spacing-base);
    border-bottom: 1px solid $color__border--darker;
  }


  @include respond-to('between') {

    &__component {
      & + & {
        margin-top: em($spacing__gutter);
      }

      & + &--spaced,
      &--spaced + &--spaced { 
        margin-top: em($spacing__module--between);
      }
    }
    
    &__ctalist {
      margin-top: em($spacing__module)
    }
  }
}
