.global-flyout {
  $global-search-height: 60px;
  @include transition(transform $transition-duration $transition-easing);

  background-color: $color__gray-dim;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
  height: 100vh;
  transform: translateY(-100%);

  &--is-expanded {
    transform: translateY(0%);
  }

  &__container {
    height: 100%;
    overflow: auto;
    padding-top: em($global-header-height);
    padding-bottom: em($global-search-height);
  }

  &__close {
    display: none;
  }

  &__panels {
    border-top: 2px solid $color__gray-silver;
  }

  &__panel {
    &--search {
      position: absolute;
      bottom: 0;
      width: 100%;

      .searchbox {
        &__input {
          background-color: $color__gunmetal;
        }
      }
    }
  }

  @include respond-to('laptop') {
    z-index: 1;
    background-color: $color__gray-light;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform $transition-duration $transition-easing,
      visibility 1ms $transition-easing $transition-duration;

    &--is-expanded {
      @include transition(transform 0.75s);
      visibility: visible;
      transform: translateX(0%);
    }

    &__panel {
      display: none;
      height: 100%;

      &--is-active {
        display: block;
      }

      &--search {
        background-color: $color__gray-dim;
        position: static;
        bottom: auto;
        padding: em($spacing__module--between) em($spacing__gutter);
        
        .searchbox {
          &__input {
            background-color: transparent;
          }
        }
      }
    }
    
    &__panels {
      border-top: 0 none;
      height: 100%;
      background-color: $color__gray-dim;
    }

    &__container {
      padding-top: em($global-header-height--tablet);
      padding-bottom: 0;
      .global-header--condensed & {
        padding-top: 4.5em;
      }
      
    }

    &__actions {
      display: block;
      position: absolute
    }

    &__close {
      display: block;
      color: $color__white;
      position: absolute;
      top: em($global-header-height--tablet + $spacing__gutter);
      right: em($spacing__gutter);

      .icon {
        display: block;
        font-size: em(24px);
        padding-bottom: 0.1em;
      }
    }
  }

  @include respond-to('desktop') {
     &__actions {
      width: em($logo-width--desktop + $wrapper-padding--desktop*2);
     }
  }
}