.client-brief {
  .flip-card {
    margin-bottom: 1.25em;
  }

  &__figure {
    display:inline-block;
    background-color: $color__gray-soft;
  }

  &__img {
    display:block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &--hidden {
      visibility: hidden;
      z-index: -1;
      position: relative;
    }
  }


  &__visit-site {
    display: inline-block;
    margin-top: em($spacing__sm);
    position: relative;
  }


  @include respond-to('between') {
    
    &__figure {
      width:100%;
      margin-right:  0;
      text-align: center;
    }
  }
}
