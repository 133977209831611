.search-item {
  display: flex;
  flex-wrap: nowrap;
  &__link {
    font-size: em(20px);

    &--aqua {
      color: $color__brand-aqua;
    }

    &--small {
      line-height: 1.2;
    }
  }


  &__figure {
    width: em(165px);
    margin-left: em(20px);
    flex-shrink: 0;

    > img {
      max-width: 100%;
      display: block;
    }
  }

  &__body {
    flex-grow: 1;
  }
  &__meta {
    margin-bottom: em(15px);
  }
  &__meta-item {
    @include font-weight('bold');
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
  }

  &__description,
  &__tag {
    margin-top: 0.5em;
  }



  @include respond-to('between') {
    

    &__figure {
      width: em(150px);
    }

    &__figure + &__body &{
      &__link {

        max-width: em(420px, $font-size__display-3--tablet);
      }
    }

    &__link {
      font-size: em(25px);
      max-width: em(700px, $font-size__display-3--tablet);
      display: inline-block;

      &--small {
        font-size: em($font-size__h2--tablet);
      }
    }
  }
  @include respond-to('desktop') {
    &__link {
      font-size: em(22px)

      &--small {
        font-size: em($font-size__h2--tablet);
      }
    }
    &__figure {
      margin-left: em(30px);
    }
  }
}
