.hamburger {
  $bun-width: px-to-em(23px);
  $bun-height: 2px;
  $bun-spacing: 5px;

  display: inline-block;
  justify-content: center;
  align-content: center;
  backface-visibility: hidden;
  padding: 0;

  &__buns {
    position: relative;
    display: block;
    width: $bun-width;
    height: px-to-em( $bun-height*3 + $bun-spacing*2);
    overflow: visible;
  }

  &__topping {
    @include transition(background-color, opacity, transform, top);

    background-color: $color__brand-chile;
    height: px-to-em($bun-height);
    display: block;
    position: absolute;
    width: 100%;

    &--middle {
      top: px-to-em( $bun-height + $bun-spacing);
    }

    &--bottom {
      top: px-to-em( $bun-height*2 + $bun-spacing*2);
    }
  }

  &--is-active &{
    &__topping {
      &--middle {
        opacity: 0;
      }

      &--top {
        transform: rotate(45deg);
        top: 50%;
      }

      &--bottom {
        transform: rotate(-45deg);
        top: 50%;
      }
    }
  }
  
  @include respond-to('between') {
    $bun-spacing: 7px;


    &:hover & {
      &__topping {
        background-color: $color__gunmetal; 
      }
    }

  }
}
