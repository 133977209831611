.toolbox {

  $grid-spacing: em($spacing__gutter);

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: $grid-spacing;
    justify-content: space-between;
  }

  &__item {
    width: calc(100% - #{$grid-spacing} / 2);
    margin-bottom: $grid-spacing;

    &__content {
      &__description {
        margin-bottom: $grid-spacing / 2;
      }
    }

    &__image {
      margin-bottom: $grid-spacing / 2;

      img {
        height: 200px;
      }
    }
  }

  @include respond-to ('between') {

    &_container {
      margin-top: 0;
    }

    &__item {
      width: calc(50% - #{$grid-spacing});
    }

  }

}