.page-tools {
  margin-bottom: em($spacing__module);

  &__label {
    display:none;
    margin-bottom: em($spacing__gutter/2);
  }
  .social-links {
    display: flex;
    justify-content: center;

    &__item {
      flex-grow: 1;
     
    }

    &__item + .social-links__item, &__item--bookmark {
      .social-links__link--boxed {
        border-left-width: 0;
      }
    }
    &__divider {
      width: em(50px);
      height: 1px;
      background-color: $color__gray-athens;
      margin: 15px 0;
      display:none;
    }
    &__link {  
      color: $color__text-gray;
      height: em(50px);

      .icon {
        font-size: 1.2em;
      }
      &--boxed {
        border-color: $color__gray-athens;
        width: 100%;
        
      }
      &:hover {
        color: rgba($color__text-gray,.6);
      }
    }
  }

  @include respond-to('desktop') {
    display: inline-block;
    text-align: center;
    &__label {
      display: block;
    }
    .social-links {
      display: block;
      &__item {
        display: block;
      }
      &__divider {
        display: block;
      }
      &__link {
        &--boxed {
          width: em(50px);
        }

        .icon {
          font-size: 1.3em;
        }

        .icon-mail {
          font-size: 1.5em;
        }
      }
      &__item + .social-links__item {
        .social-links__link--boxed {
          border-top: 0 none;
          border-left-width: 1px;
        }
      }
      &__item--bookmark {
        .social-links__link--boxed {
          border-left-width: 1px;
        }
       }
    }
  }
}
  