.print-email {
  &-container {

  }

  &__icon {
    
    width: 40px;
    cursor: pointer;
  }
  @include respond-to('between') {
    &__icon {
      margin-right: em(30px);
    }
   
  }
}