.chart {

  position: relative;
  height: em($chart-height);
  
  &__hairlines {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__hairline {
    border-top: 1px solid $color__tool-aqua-haze;
    height: 25%;

    &:last-child {
      border-bottom: 1px solid $color__tool-aqua-haze;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid $color__tool-aqua-haze;
      height: 33.333%;
    }
  }

  $bar-width: em(52px);
  &__bars { 
    width: $bar-width;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap:wrap;
    align-content: flex-end;
  }

  &__bar {
    @include transition('height');
    
    width: 100%;

    &--green {
      height: 62%;
      border-left:$bar-width solid $color__brand-green;
    }

    &--purple {
      height: 23%;
      border-left:$bar-width solid $color__brand-purple;
    }

    &--orange {
      height: 10%;
      border-left:$bar-width solid $color__brand-orange;
    }

    &--blue {
      height: 5%;
      border-left:$bar-width solid $color__brand-blue;
    }
  }

  @include respond-to('between') {
    position: relative;
    height: em($chart-height--desktop);

    &__hairline {
      border-top-width: 2px;

      &:last-child {
        border-bottom-width: 2px;
      }

      &:before,
      &:after {
        // border-bottom-width: 2px;
      }
    }

  }
}