.person-brief {
  display:flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  &__figure {
    display:block;
    width: 30%;
    margin-right:  1em;
    flex-shrink: 0;
    position: relative;

    &:before {
      content: '';
      background-color: $color__gray-soft;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      
    }
  }
  &__read-more {
    @include font-weight('semibold');
    position:relative;
    color:$color__brand-aqua;
    margin-top: 0.5em;
    display: inline-block;
    @include ico-icon-before('chevron-right') {
      position: absolute;
      //right: em($wrapper-padding);
      right:-12px;
      top:50%;
      transform: translateY(-50%);
      font-size: 12px;
    }

  }
  &__read-more.underline:after {
    background-color:$color__brand-aqua;
  }
  &__img {
    display:block;
    max-width: 100%;
    position: relative;
  }

  &__description {
    position:relative;
    flex-grow: 1;
  }

  &__location {
    color: $color__text-gray;
  }

  &__name,
  &__location {
    margin-bottom: 0.5em;
  }

  @include respond-to('between') {
    display:block;
    // max-width: em(272px);
    margin-left: auto;
    margin-right: auto;
    
    &__figure {
      width:100%;
      margin-right:  0;
      max-width: em(272px);
      height: em(272px);
    }

    &__figure + &__description {
      margin-top: 1em;
    }
  }

  @include respond-to('between') {
    &__figure {
      max-width: em(260px);
      height: em(260px);
    }
  }
}
