$font-size__h1: 30px !default;
$font-size__h2: 20px !default;
$font-size__h3: 18px !default;
$font-size__h4: 16px !default;
$font-size__h5: 15px !default;
$font-size__h6: 14px !default;

$font-size__display-1: 40px;
$font-size__display-3: 20px;
$font-size__heading: 34px !default;

$font-size__blockquote: 20px;
$font-size__heading: 34px !default;

$font-size__small: 14px !default;
$font-size__smallest: 12px !default;

$font-size__copy: 16px;
$font-size__copy-lead: 20px;

$font-weights: (
  light: 300,
  regular: 400,
  semibold: 600,
	bold: 700
);

@mixin font-weight($weight: null) {
	@if map-has-key($font-weights, $weight) {
		font-weight: map-get($font-weights, $weight);
	}
}


.titles-h1 {
  @include font-weight('semibold');

  font-size: em($font-size__h1);
  line-height: 1;
}

.titles-h2 {
  @include font-weight('semibold');
  
  font-size: em($font-size__h2);
  line-height: 1.1
}

.titles-h3 {  
  @include font-weight('semibold');
  line-height: 1.1;
  font-size: em($font-size__h3);
}

.titles-h4 {
  @include font-weight('semibold');
  line-height: 1.4;
  font-size: em($font-size__h4);
}

// Display Heading
.titles-display1 {
  @include font-weight('bold');
  
  font-size: em($font-size__display-1);
  line-height: 1.2;
}

.titles-display2 {
  @include font-weight('semibold');
  
  font-size: em($font-size__heading);
  line-height: 1.05;
}

.titles-display3 {
  @include font-weight('semibold');
  
  font-size: em($font-size__display-3);
  line-height: 1.2;
}
