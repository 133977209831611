.promo-story {
  background-color: $color__gray-soft;

  &__label {
    display:block;
    margin-bottom: 2em;
  }

  &__cta {
    margin-top: em($spacing__sm);
  }


  &__figure {
    @include bg-cover();

    width: 100%;
    padding-bottom: 72%;
    background-position: 50%;
  }

  &__body {
    padding: em($spacing__sm) em($wrapper-padding) em($spacing__lg);
  }

  @include respond-to('landscape') {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    &__figure {
      width: 40%;
      flex-shrink: 0;
      padding-bottom:0;
    }
  }

  @include respond-to('between') {

    &__body {
      padding: em($spacing__module) em($wrapper-padding--tablet) em($spacing__xl); 
      min-height: em(420px);
      
    }

    &__title {
      display: inline-block;
      max-width: em(600px, $font-size__display-3--tablet);
    }
  }

  @include respond-to('desktop-large') {
    &__figure {
      max-width: em(600px);
    }
  }
}
