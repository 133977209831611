.tool-header {
  display:flex;
  margin-bottom: em(32px);
  &__title {
    font-size: $font-size__h2--tablet;
  }
  &__description {
    font-size: $font-size__copy--desktop;
  }
  &__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $color__black;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width:2.5em;
    height:2.5em;
    box-sizing: border-box;
    margin-right: 1em;
  }
  &__text {
    width:95%;
    display: inline-block;
  }
}