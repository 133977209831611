.linked-list {
  $icon-size: 18px;
  &__option {
    padding: 1em 0;
    padding-right: em($spacing__unit + $icon-size);
    position: relative;
    border-bottom: 1px solid $color__border;

    &:first-child {
      border-top: 1px solid $color__border;
    }
  }

  &__link {
    @include transition(color);

    color: $color__link;
    display: inline-block;
  }

  &--dark & {
    &__option {
      border-color: $color__border--darker;
    }
  }

  @include respond-to('between') {
    &--dark & {
      &__link {
        &:after {
          color: $color__white;
        }
      }
    }

    &__link {
      font-size: em($font-size__copy--desktop);

      &:hover {
        color: $color__link--hover;
      }

      @include ico-icon-after('chevron-right') {
        @include centeralign(vertical);

        right: 0;
        color: $color__gray-silver;
        font-size: em($icon-size);
      }
    }
  }
}
