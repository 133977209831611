.workpath-grid {
  @include transition(opacity 0.5s);

  padding-top: em($global-header-height);
  position: absolute;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
  z-index: 2;
  height: 100%;

  &--deactivate {
    transition: opacity 0.5s $transition-easing,
      visibility 100ms linear 0.5s;
    
    visibility: hidden;
    opacity: 0;
  }
  
  &__column {
    height: 100%;
    display: flex;
    align-items: center;
  }

  
  @include respond-to('between') {
    padding-top: em($global-header-height--tablet);
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    &__column {
      width: 100%;
      display: inline-flex;

      & + & {
        border-top: 0 none;
      }
    }
  }
}
