.profile-details {
    position: relative;

  &__title {
    //margin-bottom: em($spacing-base/2, $font-size__h2--tablet);
  }

    &__related-link {
        @include font-weight('semibold');
    }

    &__link {
        color: $color__brand-aqua;
        cursor: pointer;
    }

    &__list {
        margin-top: em($spacing__gutter);
    }

    &__list-option {
        display: flex;

        & + & {
            margin-top: em($spacing__unit);
        }
    }

    &__visit {
        margin-top: em($spacing__gutter/1.5);
    }

    &__field {
        width: em(105px, $font-size__small);
        flex-shrink: 0;
        margin-right: 1em;
        line-height: 1.8em;

        &:after {
            content: ':';
        }
    }

    &__definition {
        flex-grow: 1;

        &-option {
            //margin-bottom: em($spacing__unit);
        }
    }

    .social-links {
        position: absolute;
        right: 0;
        margin: 2em;

    &__item {
      &:not(:last-child) {
        margin-right: 1em;
      }
    }

        &__link {
            color: $color__text-gray;

            &--boxed {
                border-color: $color__gray-athens;
            }

            &:hover {
                color: rgba($color__text-gray,.6);
            }
        }
    }

  @include respond-to('between') {
    &__title {
      //margin-bottom: em($spacing-base/2, $font-size__h2--tablet);
    }

    &__list {
      margin-top: em($spacing__bt-sm);
    }

    &__field {
      width: em(120px, $font-size__small);
      margin-right: 1em;
      line-height: 2;
    }

    &__definition,
    &__link {
      font-size: em($font-size__h4--tablet);
    }

    &__definition {
      &-option {
        //margin-bottom: em($spacing__unit, $font-size__h4--tablet);
      }
    }
}
}
